<template>
    <div v-if="status && status !== 'default'" class="user-status">
        <div>
            <IconUserPentagon stroke="{2}" width="32px" />
        </div>
        <div class="status-content">
            <div>
                <p>
                    Status:
                    <span>{{ displayStatus }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { IconUserPentagon } from '@tabler/icons-vue'
    import { computed, defineProps, onMounted, reactive, ref } from 'vue'
    import { useUserStore } from '@/stores/user'
    import { useTemporaryMatchStore } from '@/stores/temporaryMatch'
    import axios from 'axios'

    const props = defineProps({
        status: String
    })
    const userStore = useUserStore()
    const temporaryMatchStore = useTemporaryMatchStore()

    const temporaryMatch = computed(
        () => temporaryMatchStore.temporaryMatch?.[0]
    )
    const hasUserPlus = computed(() => {
        return userStore.user?.paymentPlus
    })

    const usersHasDifferentPayments = ref(false)

    const displayStatus = computed(() => {
        if (
            usersHasDifferentPayments.value &&
            !hasUserPlus.value &&
            !!temporaryMatch.value
        ) {
            return 'Profil przedstawiony użytkownikowi z pakietem "Plus"'
        }

        switch (props.status) {
            case 'temporary-disabled':
                return 'Konto tymaczasowo zablokowane'
            case 'waiting-for-questionnaire':
                return 'Oczekiwanie na kwestionariusz'
            case 'questionnaire-in-verification':
                return 'Weryfikacja kwestionariusza'
            case 'waiting-for-match':
                return 'Oczekiwane na dopasowanie'
            case 'waiting-for-response':
                return 'Oczekiwanie na odpowiedź drugiej strony'
            case 'questionnare-delivered':
                return 'Kwestionariusz dostarczony'
            case 'in-temporary-match':
                return 'Aktywne dopasowanie'
            case 'in-match':
                return 'Połączony z innym użytkownikiem'
        }
    })

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    const checkPaymentsDiff = async () => {
        const authToken = getCookie('authToken')

        const config = {
            headers: { Authorization: `Bearer ${authToken}` }
        }

        const temporaryMatchId = temporaryMatch.value?._id

        try {
            const response = await axios.post(
                `${process.env.VUE_APP_API}api/temporarymatch/check-plus-difference/`,
                { temporaryMatchId },
                config
            )

            usersHasDifferentPayments.value = response.data.different
        } catch (error) {
            console.log(error)
        }
    }

    onMounted(() => {
        !!temporaryMatch && checkPaymentsDiff()
    })
</script>

<style lang="scss" scoped>
    .user-status {
        display: flex;
        align-items: flex-start !important ;
        p {
            font-size: 1.6rem;
            line-height: 3rem;
            color: #000000;
            font-weight: 700;
            text-transform: none;
            font-family: 'Open Sans', sans-serif;
        }
    }

    .tabler-icon {
        stroke: #d13724;
        margin-right: 26px;
    }
</style>
