import { defineStore } from 'pinia'
import axios from 'axios'

export const useTemporaryMatchStore = defineStore('temporarymatch', {
    state: () => ({
        temporaryMatch: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async getTemporaryMatch() {
            const authToken = this.getCookie('authToken')
            const config = {
                headers: { Authorization: `Bearer ${authToken}` }
            }
            try {
                const response = await axios.get(
                    `${
                        process.env.VUE_APP_API
                    }api/temporaryMatch/user/${localStorage.getItem('userId')}`,
                    config
                )
                this.temporaryMatch = response.data
            } catch (e) {
                console.log(e)
            }
        }
    }
})
