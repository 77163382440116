import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        questionnaire: null
    }),
    actions: {
        getCookie(name) {
            const value = `; ${document.cookie}`
            const parts = value.split(`; ${name}=`)
            if (parts.length === 2) {
                return parts.pop().split(';').shift()
            }
        },
        async getUser() {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const userID = localStorage.getItem('userId')

                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/users/userData/${userID}`,
                    config
                )

                this.user = response.data
            } catch (error) {
                console.error(error)
                console.log(`[user store] error setting user`)
            }
        },
        async getUserQuestionnaire() {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const userID = localStorage.getItem('userId')

                const response = await axios.get(
                    `${process.env.VUE_APP_API}api/users/userData/questionnaire/${userID}`,
                    config
                )

                this.questionnaire = response.data
            } catch (error) {
                console.error(error)
                console.log(`[user store] error setting user questionnaire`)
            }
        },
        updateQuestionnaire(newData) {
            this.questionnaire = { ...this.questionnaire, ...newData }
        },
        async updateOnePhoto(photo, index) {
            try {
                const authToken = this.getCookie('authToken')

                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }

                const formData = new FormData()

                formData.append('userid', this.user._id)
                formData.append('photoIndex', index)
                formData.append(`file[${index}]`, photo)

                const response = await axios.post(
                    `${process.env.VUE_APP_API}api/edit-user/photo`,
                    formData,
                    config
                )

                console.log(response)
                console.log(response.data?.user)
            } catch (e) {
                console.log(e)
            }
        },
        async updateUserPhoto(photo, index, deletePhoto = false) {
            try {
                if (deletePhoto) {
                    const imgPlaceholder = require('@/assets/images/ludzik1.png')
                    this.user[`photo${index}`] = imgPlaceholder
                    return
                }

                this.user[`photo${index}`] = URL.createObjectURL(photo)
            } catch (e) {
                console.log(e)
            }
        }
    }
})
