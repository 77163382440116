<template>
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
</template>

<style lang="scss">
  @import '@/assets/scss/main.scss';
  @import '@/assets/scss/Nunito.css';
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/breakpoints.scss';
  @import '@/assets/scss/containers.scss';
  @import '@/assets/scss/global.scss';
  @import '@/assets/scss/components/notification/user-profile-notification.scss';

  nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>

<script setup>
  import { onMounted } from 'vue'
  import { useCookiebot } from '@ambitiondev/vue-cookiebot'

  // You can provide optional settings for consent banner
  const { consentBanner } = useCookiebot(
    '62926474-ded8-410b-a5a1-2a1917b89ccf',
    {
      culture: 'pl'
    }
  )

  // Init consent banner
  onMounted(() => {
    consentBanner()
  })
</script>
