<template>
    <div class="matching-process">
        <!-- Updates List -->
        <div class="updates-list">
            <TransitionGroup name="list">
                <div
                    v-for="update in matchUpdates"
                    :key="update.id"
                    class="update-item"
                    :class="[
                        update.type,
                        { 'error-update': update.type.includes('error') }
                    ]"
                >
                    <div class="update-timestamp">
                        {{
                            formatTimestamp(update.timestamp) ===
                            'Invalid Date1'
                                ? ''
                                : formatTimestamp(update.timestamp)
                        }}
                    </div>
                    <header>
                        <div class="update-icon">
                            {{ getUpdateIcon(update.type) }}
                        </div>
                        <div class="update-title">
                            <h4>{{ getUpdateTitle(update) }}</h4>
                        </div>
                    </header>

                    <div class="update-content">
                        <div class="update-details">
                            <template v-if="update.type === 'match_finding'">
                                Szukam pierwszego mozliwego dopasowania dla:
                                {{ update.data?.userId }}
                            </template>
                            <template
                                v-if="
                                    update.type ===
                                    'match_stage_initial_matching'
                                "
                            >
                                Potencjalnych kandydatów:
                                {{ update.data?.count }}
                            </template>
                            <template
                                v-if="
                                    update.type ===
                                    'match_stage_height_filtering'
                                "
                            >
                                Po zastosowaniu filtrów dot. wzrostu, pozostało:
                                {{ update.data?.count }}
                            </template>
                            <template v-if="update.type === 'match_created'">
                                Utworzono dopasowanie między
                                {{ update.data?.user1?.id }} i
                                {{ update.data?.user2?.id }}
                            </template>

                            <template v-else-if="update.error">
                                {{ update.error }}
                            </template>
                            <template v-else>
                                {{ update.message }}
                            </template>
                        </div>
                    </div>
                </div>
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import axios from 'axios'
    const API_URL = process.env.VUE_APP_API

    const eventSource = ref(null)
    const isConnected = ref(false)
    const error = ref(null)
    const matchUpdates = ref([])

    const UPDATE_ICONS = {
        connected: '🟢',
        match_finding: '✅',
        match_created: '✅',
        process_started: '🔄',
        match_completed: '🏁',
        users_found: '👥',
        match_error: '❌',
        connection_error: '🔌',
        reconnecting: '🔄',
        default: 'ℹ️'
    }

    const UPDATE_TITLES = {
        connected: 'Połączono',
        match_created: 'Utworzono match',
        process_started: 'Rozpoczynam..',
        match_error: 'Error',
        process_completed: 'Zakończono',
        users_found: 'Znaleziono użytkowników',
        connection_error: 'Connection Error',
        reconnecting: 'Reconnecting',
        default: 'Aktualizacja'
    }

    const getCookie = name => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) return parts.pop().split(';').shift()
    }

    const disconnectSSE = () => {
        if (eventSource.value) {
            eventSource.value.close()
            eventSource.value = null
            isConnected.value = false
        }
    }

    const handleUpdate = data => {
        console.log('Adding update:', data)
        matchUpdates.value.unshift({
            ...data,
            id: `${Date.now()}-${matchUpdates.value.length}`
        })
    }

    const handleConnectionError = () => {
        disconnectSSE()
        // Attempt to reconnect after a delay
        setTimeout(() => {
            connectSSE()
        }, 5000)
    }

    const connectSSE = async () => {
        const authToken = getCookie('authToken')
        if (!authToken) {
            error.value = 'No authentication token found'
            return
        }

        try {
            // Create the SSE connection with credentials
            const fullUrl = `${API_URL}api/users/match-updates`
            eventSource.value = new EventSource(fullUrl, {
                withCredentials: true
            })

            eventSource.value.onopen = () => {
                console.log('SSE Connection opened')
                isConnected.value = true
                error.value = null
            }

            eventSource.value.onmessage = event => {
                console.log('Received SSE event:', event.data) // Add this line
                try {
                    const data = JSON.parse(event.data)
                    handleUpdate(data)
                } catch (err) {
                    console.error('Error parsing SSE data:', err)
                }
            }

            eventSource.value.onerror = err => {
                console.error('SSE Error:', err)
                isConnected.value = false
                error.value = 'Connection error'
                handleConnectionError(err)
            }
        } catch (err) {
            console.error('SSE Connection error:', err)
            error.value = 'Failed to establish connection'
            handleConnectionError()
        }
    }

    const getUpdateIcon = type => {
        return UPDATE_ICONS[type] || UPDATE_ICONS.default
    }

    const getUpdateTitle = update => {
        return UPDATE_TITLES[update.type] || UPDATE_TITLES.default
    }

    const formatTimestamp = timestamp => {
        try {
            return new Date(timestamp).toLocaleTimeString()
        } catch {
            return ''
        }
    }

    onMounted(() => {
        connectSSE()
    })

    onBeforeUnmount(() => {
        disconnectSSE()
    })
</script>

<style scoped>
    header {
        display: flex;
        gap: 4px;
    }
    .matching-process {
        padding: 1rem;
        margin: 0 auto;
    }

    /* Connection Status */
    .connection-status {
        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        border-radius: 6px;
        background-color: #fee2e2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.3s ease;
    }

    .connection-status.connected {
        background-color: #dcfce7;
    }

    .status-indicator {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 500;
    }

    /* Updates List */
    .updates-list {
        background-color: #fff;
        border-radius: 6px;
        min-height: 500px;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
        width: 100%;
        margin-bottom: 30px;
        padding: 30px;
        gap: 20px;
    }

    .update-item {
        background: white;
        border-radius: 6px;
        padding: 1rem;
        margin-bottom: 0.75rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border-left: 4px solid #e2e8f0;
        transition: all 0.2s ease;
    }

    .update-item:hover {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);
    }

    .update-content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .update-content h4 {
        color: #1e293b;
        font-size: 1.4rem;
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
    }

    .update-details {
        color: #424243;
        font-size: 1.6rem;
        line-height: 1.5;
        font-weight: 400;
        margin-top: 10px;
    }

    /* Event Type Specific Styles */
    .match_created {
        border-left-color: #22c55e;
    }

    .process_started {
        border-left-color: #3b82f6;
    }

    .users_found {
        border-left-color: #8b5cf6;
    }

    .process_completed {
        border-left-color: #10b981;
    }

    .error-update {
        border-left-color: #ef4444;
        background-color: #fef2f2;
    }

    /* Transitions */
    .list-enter-active,
    .list-leave-active {
        transition: all 0.3s ease;
    }

    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }

    .list-move {
        transition: transform 0.3s ease;
    }

    /* Optional: Style for the timestamps */
    .update-timestamp {
        color: #5f5f60;
        font-size: 1.3rem;
        margin-bottom: 5px;
    }

    /* Error state */
    .error-update .update-content h4 {
        color: #dc2626;
    }

    .error-update .update-details {
        color: #ef4444;
    }

    /* Connection indicator dot */
    .status-indicator::before {
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #dc2626;
        margin-right: 8px;
    }

    .connected .status-indicator::before {
        background-color: #22c55e;
    }

    /* Optional: Make text selectable for copying */
    .update-details {
        user-select: text;
    }

    /* Optional: Add scrollbar styling */
    .updates-list {
        max-height: 600px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #cbd5e1 #f1f5f9;
    }

    .updates-list::-webkit-scrollbar {
        width: 6px;
    }

    .updates-list::-webkit-scrollbar-track {
        background: #f1f5f9;
        border-radius: 3px;
    }

    .updates-list::-webkit-scrollbar-thumb {
        background-color: #cbd5e1;
        border-radius: 3px;
    }

    /* Responsive adjustments */
    @media (max-width: 640px) {
        .matching-process {
            padding: 0.5rem;
        }

        .update-item {
            padding: 0.75rem;
        }

        .update-content h4 {
            font-size: 0.95rem;
        }

        .update-details {
            font-size: 0.875rem;
        }
    }
</style>
