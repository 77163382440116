<template>
    <div class="notification">
        <!-- <div
            class="passchange-wrapper"
            v-if="passChange && nodeENV !== 'development'"
        >
            <div class="passchange">
                <div class="passchange__top">Okresowa zmiana hasła</div>
                <div class="passchange__bottom">
                    <input
                        v-model="currentPassword"
                        type="password"
                        placeholder="Obecne hasło"
                        class="passchange__input"
                    />
                    <input
                        v-model="newPassword"
                        type="password"
                        placeholder="Nowe hasło"
                        class="passchange__input"
                    />
                    <input
                        v-model="confirmPassword"
                        type="password"
                        placeholder="Potwierdź nowe hasło"
                        class="passchange__input"
                    />
                    <button
                        @click="changePassword"
                        :disabled="!isFormValid"
                        class="passchange__button"
                    >
                        Zmień hasło
                    </button>
                    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
                    <p v-if="successMessage" class="success">
                        {{ successMessage }}
                    </p>
                </div>
            </div>
        </div> -->
        <ul v-if="announcementsSaw.length >= 0" class="notification__list">
            <Notification
                v-for="el in announcementsSaw"
                :key="componentKey"
                :title="el.title"
                :content="el.message"
                :date="el.date"
                :userId="userId"
                :secondUserId="el.toUserId"
                :announcementId="el._id"
                :slotComponent="el.slotComponent"
                @deleteClick="confirmDeleteAction"
            ></Notification>
            <!-- #region CONSULTANT -->
            <!-- <span v-if="user">
                <Notification 
                    v-if="user.consultant"
                    :content='"Twoim konsultantem jest " + user.consultant'
                    notDelete="true"
                />
            </span> -->
            <!-- #endregion -->

            <!-- dodac komunikat dziekujemy za wprowadzone zmiany -->

            <Notification
                v-if="user?.paymentPlus === true"
                :title="paymentPlusTitle"
                :content="paymentPlusContent"
                :notDelete="true"
            />

            <Notification
                v-if="user?.isVerifiedByAdmin === true"
                :title="verifiedTitle"
                :content="verifiedContent"
                :notDelete="true"
            />

            <Notification
                v-if="
                    questionnaireDeclined != 'nothing' &&
                    questionnaireDeclined?.questionnaire?.done == true &&
                    'jobImportance' in questionnaireDeclined
                "
                :title="questionnaireDeclinedTitleWaiting"
                :content="questionnaireDeclinedContentWaiting"
                :notDelete="true"
            />

            <Notification
                v-if="
                    questionnaireDeclined != 'nothing' &&
                    questionnaireDeclined?.questionnaire?.done == false
                "
                :title="questionnaireDeclinedTitle"
                :content="questionnaireDeclinedContent"
                :notDelete="true"
            />

            <Notification
                v-if="user?.paymentBasic === true"
                :title="paymentTitle"
                :content="paymentContent"
                :notDelete="true"
            />
            <Notification
                v-if="user && user.allowQuestionnaire"
                :title="allowQuestionsTitle"
                :content="allowQuestions"
                :notDelete="true"
            />
            <Notification
                :title="globalTitle"
                :content="globalContent"
                :notDelete="true"
            />
        </ul>
        <p v-else class="notification__error">Brak powiadomień.</p>
        <div
            :class="[
                { showDeleteBox: showDeleteBox },
                'notification__delete-confirm'
            ]"
        >
            <div class="notification__delete-confirm-content">
                <p class="notification__title">Chcesz usunąć powiadomienie?</p>
                <div class="notification__content">
                    Nie będzie już widoczne w panelu powiadomień.
                    <!-- userID to delete:{{ userIdToDelete }} announcementID to delete
          {{ announcementIdToDelete }} -->
                </div>
                <div class="notification__delete-confirm-buttons">
                    <button @click="closeAnnouncement(announcementIdToDelete)">
                        Tak
                    </button>
                    <button @click="showDeleteBox = false">Nie</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import Notification from '@/components/atoms/Notification.vue'
    import Footer from '@/components/landing-03/footer.vue'
    import { Thumbs } from 'swiper/modules'
    import { ref } from 'vue'

    export default {
        data() {
            return {
                componentKey: 0,
                globalTitle: 'Wiadomość powitalna.',
                allowQuestionsTitle:
                    'Kwestionariusz zgłoszeniowy - ważne informacje.',
                paymentTitle: 'Dziękujemy za płatność!',
                paymentContent: `Nasz zespół zajmie się sprawdzeniem Twojego formularza i jeśli pojawią się kwestie, które będą wymagały doprecyzowania lub wyjaśnienia, otrzymasz od nas wiadomość z prośbą o uzupełnienie lub korektę odpowiedzi.
            <br><br>
            Jeśli nie będzie konieczności wprowadzania zmian, Twój Profil Singla pojawi się w naszej bazie w trakcie 14 dni roboczych, chyba, że nie wyrażono zgody na natychmiastowe rozpoczęcie świadczenia usługi.  Od razu poinformujemy Cię o jego pozytywnej weryfikacji. W Twoim profilu pojawi się wówczas możliwość podwyższenia wybranego pakietu.
            <br><br>
            Cieszymy się, że jesteś z nami!<br>
            Basia i Zespół Biura Randkowego`,
                paymentPlusTitle:
                    'Potwierdzenie płatności za pakiet Plus i rozpoczęcie poszukiwań.',
                paymentPlusContent:
                    'Dziękujemy za dokonanie płatności za pakiet Plus w naszym Biurze Randkowym. Cieszymy się na naszą współpracę i jesteśmy gotowi rozpocząć aktywne poszukiwania idealnego dopasowania dla Ciebie.<br><br>Od tej chwili nasz zespół będzie działał aktywnie, aby znaleźć osobę, która spełni Twoje oczekiwania i najlepiej będzie odpowiadać podanym przez Ciebie preferencjom. Jeśli pojawi się dopasowanie, natychmiast poinformujemy Cię o tym oraz przydzielimy dostęp do naszej dedykowanej aplikacji dla klientów.<br><br>Prosimy Cię o cierpliwość - przeczesywanie bazy w poszukiwaniu najlepiej dopasowanych do Ciebie osób to proces wymagający czasu i uwagi. W Biurze Randkowym stawiamy przede wszystkim na jakość i działanie przynoszące wymierne efekty. Pamiętaj, że będziemy o wszystkim informować Cię na bieżąco za pośrednictwem maila i/lub sms-a.<br><br>Dziękujemy jeszcze raz za zaufanie,<br><br>Basia i Zespół Biura Randkowego',
                verifiedTitle: 'Weryfikacja przebiegła pomyślnie.',
                verifiedContent: '',
                questionnaireDeclinedTitle:
                    'Wymagane zmiany w kwestionariuszu.',
                questionnaireDeclinedContent: '',
                questionnaireDeclinedTitleWaiting:
                    'Dziękujemy za poprawę kwestionariusza.',
                questionnaireDeclinedContentWaiting:
                    'Dziękujemy za wprowadzenie zmian w kwestionariuszu. Ich weryfikacja może zająć maksymalnie 7 dni, ale zrobimy wszystko, żeby jak najszybciej zatwierdzić wprowadzone zmiany. O zatwierdzeniu kwestionariusza poinformujemy Cię mailowo oraz w zakładce „Powiadomienia” w Twoim panelu klienta.<br><br>Z pozdrowieniami,<br><br>Zespół Biura Randkowego',
                globalContent: '',
                allowQuestions: '',
                show: false,
                user: null,
                userName: '',
                userId: '',
                showDeleteBox: false,
                deleteConfirm: false,
                announcements: [],
                announcementsSaw: [],
                userIdToDelete: '',
                announcementIdToDelete: '',
                questionnaireDeclined: null,
                envUrl: '',
                passChange: false,
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                errorMessage: '',
                successMessage: ''
            }
        },
        components: {
            Footer,
            HeaderComponent,
            Notification
        },
        computed: {
            isFormValid() {
                return (
                    this.currentPassword &&
                    this.newPassword &&
                    this.confirmPassword &&
                    this.newPassword === this.confirmPassword &&
                    this.newPassword !== this.currentPassword
                )
            }
        },
        methods: {
            forceRender() {
                console.log('force render')
                this.componentKey += 1
            },
            getQuestionnaireDeclined() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(
                        `${
                            this.url
                        }api/questionnaire-declined/donequestionnaire/${localStorage.getItem(
                            'userId'
                        )}`,
                        config
                    )
                    .then(resp => {
                        this.questionnaireDeclined = resp.data
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            setGlobalContent() {
                this.globalContent = `Hej ${this.userName}, <br><br>
                z ogromną radością informuję, że Twój panel klienta został pomyślnie aktywowany, a Twoje zgłoszenie zostanie niebawem przekazane do jednej ze współpracujących ze mną, doświadczonych konsultantek lub konsultanta. Od tej pory to właśnie w tym miejscu będziesz widzieć wszystkie informacje ode mnie lub od moich współpracowników.<br><br>
                Cieszę się niezmiernie z Twojej decyzji o dołączeniu do grona moich Klientek i Klientów i z niecierpliwością czekam na rozpoczęcie naszej współpracy. Wierzę, że Ty też!<br><br>
                Wkrótce otrzymasz ode mnie kolejną wiadomość z informacjami o następnym kroku w procesie znajdowania idealnego dopasowania. Bardzo proszę Cię o odrobinę cierpliwości i zapewniam, że każde zgłoszenie jest dla nas ważne i traktowane z największą starannością. Liczba zgłoszeń przerosła nasze oczekiwania, co powoduje wydłużenie czasu oczekiwania na kwestionariusz, ale jednocześnie jest dla nas powodem do dumy.<br><br>
                Serdecznie dziękujemy za zaufanie i rejestrację na liście oczekujących. Do zobaczenia wkrótce!<br><br>
                Z wyrazami szacunku,<br>
                Basia i Zespół Biura Randkowego`
                this.allowQuestions = `Witaj ${this.userName},<br><br>

                Miło mi poinformować, że Twój kwestionariusz zgłoszeniowy jest gotowy do uzupełnienia.<br><br>

                Abyśmy mogli jak najlepiej dopasować dla Ciebie drugą osobę, prosimy o dokładne i przemyślane wypełnienie kwestionariusza zgłoszeniowego. Oto kilka wskazówek, które pomogą Ci w tym procesie:<br><br>

                <strong>Znajdź spokojne miejsce:</strong> Wypełniaj kwestionariusz w miejscu, które pozwala Ci na skupienie i spokój. Upewnij się, że nic Cię nie rozprasza, abyw pełni skoncentrować się na pytaniach i swoich odpowiedziach.<br><br>

                <strong>Nie ma dobrych i złych odpowiedzi:</strong> Pamiętaj, że każde pytanie jest neutralne i nie ma „prawidłowych” lub „błędnych” odpowiedzi. Ważne jest, żeby odpowiadać szczerze, zgodnie z tym, co naprawdę czujesz i myślisz.<br><br>

                <strong>Różne rodzaje pytań:</strong> Kwestionariusz składa się z różnorodnych pytań, które mogą dotyczyć Twoich zainteresowań, wartości, preferencji dotyczących przyszłego partnera lub partnerki, stylu życia itp. Każde pytanie ma na celu lepsze poznanie Ciebie i Twoich oczekiwań.<br><br>

                <strong>Odpowiadaj zgodnie z własnymi potrzebami i oczekiwaniami:</strong> Skoncentruj się na tym, co jest dla Ciebie najważniejsze w potencjalnym związku. Odpowiedzi powinny odzwierciedlać Twoje osobiste pragnienia i kryteria, które uważasz za kluczowe w przyszłej relacji.<br><br>

                <strong>Szczerość:</strong> Bardzo ważne jest, aby wszystkie odpowiedzi były zgodne z prawdą. Szczerość zapewni większą szansę na znalezienie osoby, która podziela Twoje wartości i zainteresowania.<br><br>

                <strong>Nie można edytować kwestionariusza:</strong> Pamiętaj, że po zatwierdzeniu odpowiedzi i dokonaniu płatności, nie ma możliwości ich edycji, dlatego poświęć odpowiednią ilość czasu na przemyślenie swoich odpowiedzi. Tylko wybrane informacje z profilu będą możliwe do edycji w zakładce "Mój profil" (W tym wybrane miejscowości).<br><br>

                <strong>Wybór zdjęć:</strong> Dołącz do kwestionariusza najlepiej dwa zdjęcia - portretowe oraz przedstawiające całą sylwetkę. Jeśli zdjęcia były dołączone w procesie rejestracji na liście oczekujących, możesz je pozostawić lub zmienić. Upewnij się, że zdjęcia są zgodne z naszymi wytycznymi i dobrze Cię reprezentują. Sam zdecydujesz, które zdjęcie będzie głównym zdjęciem w Twoim profilu. Później masz możliwość edycji zdjęć w zakładce "Mój profil"<br><br>

                <strong>Prywatność informacji:</strong> Przy każdym pytaniu znajdziesz informację, czy Twoja odpowiedź będzie publicznie widoczna w Twoim profilu, czy będzie używana tylko wewnętrznie do celów dopasowania. Zastanów się, które informacje chcesz ujawnić innym użytkownikom.<br><br>

                Mam nadzieję, że te wskazówki pomogą Ci w wypełnieniu kwestionariusza. Życzę Ci powodzenia i cieszę się na możliwość pomocy w znalezieniu idealnego dopasowania!`
                this.questionnaireDeclinedContent = `Witaj ${this.userName},<br><br>Dziękuję za wypełnienie kwestionariusza w Biurze Randkowym. Chcę Cię poinformować, że zauważyłam kilka kwestii, które wymagają Twojej uwagi i ewentualnej korekty. Wszystko po to, abyśmy mogli jak najlepiej dopasować Cię do wybranych osób.<br><br>Proszę Cię o kliknięcie w poniższy link, który przeniesie Cię do Twojego kwestionariusza, gdzie oznaczyłam pytania, które wymagają zmiany lub uzupełnienia. Dokładnie wyjaśniłam, dlaczego te zmiany są istotne, abyśmy mogli zapewnić Ci jak najbardziej satysfakcjonujące efekty naszej współpracy. Jeśli uznasz, że Twoja odpowiedź ma pozostać niezmieniona lub w Twoim odczuciu nie wymaga korekty, masz do tego pełne prawo.<br><br>Kliknij <a href="/questions2">TUTAJ</a> żeby przejść do kwestionariusza.<br><br>Dziękuję za Twoją współpracę i zrozumienie.<br><br>Przesyłamy pozdrowienia,<br>Basia i Zespół Biura Randkowego.`
                this.verifiedContent = `Witaj ${this.userName},<br><br>
                Cieszę się, że Twój kwestionariusz pozytywnie przeszedł proces weryfikacji. Mam przyjemność poinformować Cię, że Twój Profil Singla jest już w naszej bazie. Raz jeszcze dziękuję za zaufanie i wybór Biura Randkowego.<br><br>
                Obecnie możesz skorzystać z opcji podniesienia swojego pakietu, aby rozpocząć pełną współpracę z nami. Możesz to zrobić klikając w poniższy link, który przeniesie Cię do zakładki, gdzie masz do wyboru dwa warianty czasowe: 3 miesiące i 6 miesięcy współpracy.<br><br>
                <a href="/panel/package">KLIKNIJ</a> i zobacz pakiety.<br><br>
                Wybór pakietu Plus oznacza aktywne rozpoczęcie poszukiwania dopasowania z bazy wszystkich obecnych osób. Jeśli zdecydujesz się pozostać przy pakiecie Basic, Twój profil będzie prezentowany osobom korzystającym z pakietów Plus lub Premium.<br><br>
                Jeśli wyrażasz zainteresowanie skorzystaniem z pakietu Premium i rozpoczęciem indywidualnej współpracy ze mną, uprzejmie proszę Cię o zgłoszenie swojej chęci poprzez wybranie odpowiedniej opcji w zakładce Twój pakiet. Liczba miejsc jest ograniczona.<br><br>
                Bez względu na to, z jakiego pakietu zdecydujesz się korzystać, wraz z pierwszym dopasowaniem otrzymasz dostęp do dedykowanej aplikacji dla klientów, która ułatwi Ci komunikację z dopasowanymi osobami.<br><br>
                Dziękujemy jeszcze raz za zaufanie i nie możemy się doczekać na dalszą współpracę z Tobą.<br><br>
                Przesyłamy pozdrowienia,<br>
                Basia i Zespół Biura Randkowego`
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getAnnouncementSaw(id) {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                }

                const announcementToUserURL = `${this.url}api/announcement/single-user/${id}`
                // const globalAnnouncementToAllUsersURL = `${this.url}api/announcement/global`

                Promise.all([
                    axios.get(announcementToUserURL, config)
                    // axios.get(globalAnnouncementToAllUsersURL, config)
                ])
                    .then(responses => {
                        const userAnnouncements = responses[0].data
                        // const globalAnnouncements = responses[1].data
                        // const combinedData = [...userAnnouncements, ...globalAnnouncements]
                        const combinedData = [...userAnnouncements]

                        const filteredData = combinedData.filter(
                            announcement => {
                                return (
                                    !('closedByUser' in announcement) ||
                                    announcement.closedByUser === false
                                )
                            }
                        )
                        this.announcementsSaw = filteredData.reverse()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            confirmDeleteAction(payload) {
                const { announcementId, userId } = payload
                this.userIdToDelete = userId
                this.announcementIdToDelete = announcementId
                this.showDeleteBox = true
            },
            closeAnnouncement(announcementId) {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                try {
                    axios
                        .put(
                            `${this.url}api/announcement/close/${announcementId}`,
                            { userId: localStorage.getItem('userId') },
                            config
                        )
                        .then(resp => {
                            const item = document.querySelector(
                                `[data-notificaton-id="${announcementId}"]`
                            )
                            item.classList.add('single-notification--is-hidden')
                            // console.log(resp)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } catch (error) {
                    console.log(error)
                }
                this.showDeleteBox = false
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getQuestionnaire() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/singleuser/${id}`, config)
                    .then(resp => {
                        this.questionnaire = resp.data
                        this.show = true
                        if (this.questionnaire == 'noquestionnaire') {
                            this.show = false
                        }
                    })
            },
            getUser() {
                // own ID
                if (!localStorage.getItem('userId')) {
                    location.replace('/login')
                }
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                axios
                    .get(
                        `${this.url}api/verify/user/${localStorage.getItem(
                            'userId'
                        )}`,
                        config
                    )
                    .then(resp => {
                        this.user = resp.data.user
                        if (this.user.isVerified == false) {
                            this.$router.push('/verify-email')
                        }
                        if (resp.data.questionnaires) {
                            if (resp.data.questionnaires[0]) {
                                if (resp.data.questionnaires[0].name) {
                                    this.userName =
                                        resp.data.questionnaires[0].name
                                }
                            }
                        }
                        this.setGlobalContent()
                        this.userId = resp.data.user._id
                        this.getAnnouncementSaw(this.userId)
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            routerPush() {
                this.$router.push('/panel/notification')
            },
            checkQuestionnaire() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/singleuser/${id}`, config)
                    .then(resp => {
                        this.questionnaire = resp?.data || {}
                        if (!this.questionnaire.name) {
                            this.$router.push('/panel/notification')
                            /*
                             * TODO:
                             *  ==> this.$router.push('/zapisz-sie') czy to przekierowanie jest ok ?  pulses/1523439426
                             *  user, ktory nie ma płatności powinien miec dostęp do konta mimo wszystko ewentualnie jakis komunikat
                             *  kwestionariusz tak i tak jest sprawdzany w UserPanel.vue
                             */
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getPassChange() {
                let id = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(
                        `${this.url}api/users/pass-change-required/${id}`,
                        config
                    )
                    .then(resp => {
                        if (resp.data == 'ok') {
                            this.passChange = true
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            async changePassword() {
                if (!this.isFormValid) {
                    this.errorMessage =
                        'Proszę wypełnić wszystkie pola poprawnie.'
                    return
                }

                try {
                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: { Authorization: `Bearer ${authToken}` }
                    }

                    const response = await axios.post(
                        `${this.url}api/register/change-password/${this.userId}`,
                        {
                            currentPassword: this.currentPassword,
                            newPassword: this.newPassword
                        },
                        config
                    )

                    this.successMessage = response.data.message
                    this.errorMessage = ''
                    this.currentPassword = ''
                    this.newPassword = ''
                    this.confirmPassword = ''

                    // Wylogowanie po pomyślnej zmianie hasła
                    await this.logout()
                } catch (error) {
                    this.errorMessage =
                        error.response?.data?.message ||
                        'Wystąpił błąd podczas zmiany hasła.'
                    this.successMessage = ''
                }
            },
            async logout() {
                try {
                    await axios.post(`${this.url}api/logout`)

                    this.deleteAuthCookie()

                    this.$store.commit('setAuth', false)

                    this.$router.push('/login')
                } catch (error) {
                    console.error(error)
                }
            },
            deleteAuthCookie() {
                document.cookie =
                    'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
            }
        },
        created() {
            // this.getPassChange()
            this.getUser()
            this.getQuestionnaire()
            this.getQuestionnaireDeclined()

            this.routerPush()
            this.checkQuestionnaire()
        },
        mounted() {
            this.routerPush()
            this.envUrl = process.env.VUE_APP_URL_GLOBAL
            // this.verifiedContent = `Możesz podwyższyć swój pakiet <a href="${this.envUrl}panel/package">TUTAJ</a> lub pozostać przy pakiecie Basic.`;
            this.verifiedContent = `Możesz podwyższyć swój pakiet <a href="${this.envUrl}panel/package">TUTAJ</a> lub pozostać przy pakiecie Basic.`
        }
    }
</script>

<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .passchange-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vh;
        height: 100vh;
        background-color: rgb(246, 244, 240, 0.8);
        z-index: 9999;
        width: 100%;
    }

    .passchange {
        display: flex;
        flex-direction: column;
        z-index: 9999;
        min-width: 30vw;
        min-height: 20vh;
        background-color: #f6f4f0;
        border: 1px solid black;
        box-shadow: 0 0 1px black;
        &__top {
            text-align: center;
            background-color: #d13724;
            color: #ffffff;
            padding: 10px;
            font-weight: 700;
            font-size: 16px;
        }
        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            gap: 16px;
        }
        &__input {
            max-width: 300px;
            padding: 8px;
            background-color: transparent;
            border-radius: 0;
            border: 1px solid black;
            font-size: 16px;
            &::placeholder {
                font-size: 12px;
            }
        }
        &__button {
            color: #ffffff;
            padding: 10px 20px;
            background-color: #d13724;
            font-weight: 800;
            font-size: 18px;
            cursor: pointer;
            &:disabled {
                opacity: 0.2;
                cursor: not-allowed;
            }
        }
    }

    .notification {
        padding-right: 30px;
        height: 100%;
        padding-bottom: 50px;

        @include breakpoint-max('xs-tablet') {
            padding-left: 30px;
        }

        &__title {
            margin-bottom: 20px;
            font-size: 2.4rem;
        }

        &__content {
            font-size: 1.6rem;
            max-width: 60%;
            margin: 0 auto;
        }
        &__list {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            width: 100%;
            max-width: 984px;
            list-style: none;
            @include breakpoint-max('xs-tablet') {
                row-gap: 15px;
            }
        }
        &__error {
            padding-left: 350px;
            font-size: 1.6rem;
            line-height: 2.4rem;
            font-weight: 700;
            padding-top: 100px;
            @include breakpoint-max('tablet') {
                padding-left: 0;
                text-align: center;
            }
            @include breakpoint-max('xs-tablet') {
                padding-top: 50px;
            }
        }
        .showDeleteBox {
            opacity: 1;
            z-index: 999;
        }
        &__delete-confirm {
            opacity: 0;
            z-index: -1;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            display: grid;
            place-items: center;
            //transition: 0.3s;
            backdrop-filter: blur(2px);
            background: rgba(255, 255, 255, 0.5);
        }
        &__delete-confirm-content {
            padding: 60px 50px;
            background-color: $pink;
            font-size: 1.6rem;
            line-height: 2.4rem;
            text-align: center;
            color: $white;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            @include breakpoint-max('mobile') {
                width: 90vw;
            }
        }
        &__delete-confirm-buttons {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 30px;
            button {
                padding: 10px 30px;
                border: none;
                outline: none;
                text-transform: uppercase;
                background-color: #e1dbd5;
                font-size: 1.6rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                color: #000;
                appearance: none;
                cursor: pointer;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }
</style>
