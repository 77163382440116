<template>
    <div class="user-panel">
        <HeaderComponent />
        <div class="sidebar">
            <header class="user-panel__header">
                <div class="user-panel__header-logo">
                    <p>Biuro</p>
                </div>
            </header>
            <aside class="user-panel__sidebar">
                <ul
                    v-if="user"
                    class="user-panel__sidebar-pages"
                    :key="componentKey"
                >
                    <!-- Mój profil -->
                    <li
                        :class="{
                            'active-link': isLinkActive('/panel/profile')
                        }"
                    >
                        <RouterLink to="/panel/profile">
                            <div class="user-panel__sidebar-pages-icon">
                                <picture v-if="user">
                                    <img :src="user.photo1" alt="" />
                                </picture>
                            </div>
                            <p>Mój profil</p>
                        </RouterLink>
                    </li>
                    <!-- Powiadomienia -->
                    <li
                        :class="{
                            'active-link': isLinkActive('/panel/notification')
                        }"
                    >
                        <RouterLink to="/panel/notification">
                            <div class="user-panel__sidebar-pages-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="25"
                                    height="27"
                                    viewBox="0 0 25 27"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_171"
                                                data-name="Rectangle 171"
                                                width="25"
                                                height="27"
                                                transform="translate(-0.333 -0.152)"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_320"
                                        data-name="Group 320"
                                        transform="translate(0.333 0.152)"
                                    >
                                        <g
                                            id="Group_246"
                                            data-name="Group 246"
                                            transform="translate(0 0)"
                                        >
                                            <path
                                                id="Path_274"
                                                data-name="Path 274"
                                                d="M18.5,8.466c-1.086-4.068-3.474-5.49-5.141-5.984v-.7a1.781,1.781,0,0,0-3.562,0v.7C8.13,2.976,5.741,4.4,4.655,8.466,3.038,14.534,3.542,19.6,0,20.037v2.226H23.154V20.037c-3.542-.438-3.038-5.5-4.655-11.571M10.686,1.781a.891.891,0,0,1,1.781,0v.5a5.345,5.345,0,0,0-.762-.061c-.083,0-.128,0-.128,0s-.045,0-.128,0a5.345,5.345,0,0,0-.762.061ZM22.263,21.373H.891v-.621c2.5-.8,3.029-3.814,3.63-7.254.254-1.455.543-3.1,1-4.8,1.417-5.313,5.191-5.581,5.933-5.581h.07l.058,0,.053,0,.075,0c.742,0,4.516.269,5.933,5.581.453,1.7.741,3.348,1,4.8.6,3.441,1.127,6.452,3.63,7.254Z"
                                                transform="translate(0.415 0.274)"
                                            />
                                            <path
                                                id="Path_275"
                                                data-name="Path 275"
                                                d="M25.343,52A2.672,2.672,0,1,1,20,52Z"
                                                transform="translate(-10.679 -28.572)"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Powiadomienia</p>
                        </RouterLink>
                    </li>
                    <!-- kwestionariusz -->
                    <li
                        class="{ 'active-link': isLinkActive('/questions2') }"
                        v-if="
                            user &&
                            user.allowQuestionnaire &&
                            user.paymentBasic != true
                        "
                    >
                        <RouterLink to="/questions2">
                            <div class="user-panel__sidebar-pages-icon">
                                <svg
                                    class="user-panel-icon--questionnaire"
                                    version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    x="0px"
                                    y="0px"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 64 64"
                                    enable-background="new 0 0 64 64"
                                    xml:space="preserve"
                                >
                                    <line
                                        fill="none"
                                        stroke="#111111"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="16"
                                        y1="24"
                                        x2="38"
                                        y2="24"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="16"
                                        y1="34"
                                        x2="38"
                                        y2="34"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="16"
                                        y1="44"
                                        x2="38"
                                        y2="44"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="16"
                                        y1="54"
                                        x2="38"
                                        y2="54"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="12"
                                        y1="24"
                                        x2="8"
                                        y2="24"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="12"
                                        y1="34"
                                        x2="8"
                                        y2="34"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="12"
                                        y1="44"
                                        x2="8"
                                        y2="44"
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="12"
                                        y1="54"
                                        x2="8"
                                        y2="54"
                                    />
                                    <polyline
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        points="14,8 1,8 1,63 45,63 45,8 32,8 "
                                    />
                                    <polygon
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        points="27,5 27,1 19,1 19,5 15,5 13,13 33,13 31,5 
                                    "
                                    />
                                    <polygon
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        points="55,1 55,54 59,62 63,54 63,1 "
                                    />
                                    <line
                                        fill="none"
                                        stroke="#ffffff"
                                        stroke-width="2"
                                        stroke-miterlimit="10"
                                        x1="55"
                                        y1="11"
                                        x2="63"
                                        y2="11"
                                    />
                                </svg>
                            </div>
                            <p>Kwestionariusz</p>
                        </RouterLink>
                    </li>
                    <!-- Twój pakiet -->
                    <li
                        v-if="user?.paymentBasic || user?.paymentPlus"
                        :class="{
                            'active-link': isLinkActive('/panel/package')
                        }"
                    >
                        <RouterLink to="/panel/package">
                            <div class="user-panel__sidebar-pages-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    id="Group_306"
                                    data-name="Group 306"
                                    width="17.516"
                                    height="27.183"
                                    viewBox="0 0 17.516 27.183"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_204"
                                                data-name="Rectangle 204"
                                                width="17.516"
                                                height="27.183"
                                                fill="#2a2a29"
                                            />
                                        </clipPath>
                                    </defs>
                                    <rect
                                        id="Rectangle_197"
                                        data-name="Rectangle 197"
                                        width="0.742"
                                        height="8.26"
                                        transform="translate(3.532 9.461)"
                                        fill="#2a2a29"
                                    />
                                    <rect
                                        id="Rectangle_198"
                                        data-name="Rectangle 198"
                                        width="0.742"
                                        height="4.888"
                                        transform="translate(6.768 12.833)"
                                        fill="#2a2a29"
                                    />
                                    <rect
                                        id="Rectangle_199"
                                        data-name="Rectangle 199"
                                        width="0.742"
                                        height="6.972"
                                        transform="translate(10.005 10.749)"
                                        fill="#2a2a29"
                                    />
                                    <rect
                                        id="Rectangle_200"
                                        data-name="Rectangle 200"
                                        width="0.742"
                                        height="2.933"
                                        transform="translate(13.242 14.788)"
                                        fill="#2a2a29"
                                    />
                                    <g id="Group_305" data-name="Group 305">
                                        <g id="Group_304" data-name="Group 304">
                                            <path
                                                id="Path_306"
                                                data-name="Path 306"
                                                d="M14.923,27.183H2.594A2.6,2.6,0,0,1,0,24.59v-22A2.6,2.6,0,0,1,2.594,0H14.923a2.6,2.6,0,0,1,2.593,2.593v22a2.6,2.6,0,0,1-2.593,2.593M2.594.742A1.853,1.853,0,0,0,.743,2.593v22a1.853,1.853,0,0,0,1.851,1.851H14.923a1.853,1.853,0,0,0,1.851-1.851v-22A1.853,1.853,0,0,0,14.923.742Z"
                                                transform="translate(-0.001)"
                                                fill="#2a2a29"
                                            />
                                            <rect
                                                id="Rectangle_201"
                                                data-name="Rectangle 201"
                                                width="16.773"
                                                height="0.742"
                                                transform="translate(0.371 4.747)"
                                                fill="#2a2a29"
                                            />
                                            <rect
                                                id="Rectangle_202"
                                                data-name="Rectangle 202"
                                                width="16.773"
                                                height="0.742"
                                                transform="translate(0.371 21.199)"
                                                fill="#2a2a29"
                                            />
                                            <rect
                                                id="Rectangle_203"
                                                data-name="Rectangle 203"
                                                width="3.165"
                                                height="0.742"
                                                transform="translate(7.175 23.936)"
                                                fill="#2a2a29"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Twój pakiet</p>
                        </RouterLink>
                        <!-- <button>
                            <div class="user-panel__sidebar-pages-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Group_306" data-name="Group 306" width="17.516" height="27.183" viewBox="0 0 17.516 27.183">
                                    <defs>
                                        <clipPath id="clip-path">
                                        <rect id="Rectangle_204" data-name="Rectangle 204" width="17.516" height="27.183" fill="#2a2a29"/>
                                        </clipPath>
                                    </defs>
                                    <rect id="Rectangle_197" data-name="Rectangle 197" width="0.742" height="8.26" transform="translate(3.532 9.461)" fill="#2a2a29"/>
                                    <rect id="Rectangle_198" data-name="Rectangle 198" width="0.742" height="4.888" transform="translate(6.768 12.833)" fill="#2a2a29"/>
                                    <rect id="Rectangle_199" data-name="Rectangle 199" width="0.742" height="6.972" transform="translate(10.005 10.749)" fill="#2a2a29"/>
                                    <rect id="Rectangle_200" data-name="Rectangle 200" width="0.742" height="2.933" transform="translate(13.242 14.788)" fill="#2a2a29"/>
                                    <g id="Group_305" data-name="Group 305">
                                        <g id="Group_304" data-name="Group 304">
                                        <path id="Path_306" data-name="Path 306" d="M14.923,27.183H2.594A2.6,2.6,0,0,1,0,24.59v-22A2.6,2.6,0,0,1,2.594,0H14.923a2.6,2.6,0,0,1,2.593,2.593v22a2.6,2.6,0,0,1-2.593,2.593M2.594.742A1.853,1.853,0,0,0,.743,2.593v22a1.853,1.853,0,0,0,1.851,1.851H14.923a1.853,1.853,0,0,0,1.851-1.851v-22A1.853,1.853,0,0,0,14.923.742Z" transform="translate(-0.001)" fill="#2a2a29"/>
                                        <rect id="Rectangle_201" data-name="Rectangle 201" width="16.773" height="0.742" transform="translate(0.371 4.747)" fill="#2a2a29"/>
                                        <rect id="Rectangle_202" data-name="Rectangle 202" width="16.773" height="0.742" transform="translate(0.371 21.199)" fill="#2a2a29"/>
                                        <rect id="Rectangle_203" data-name="Rectangle 203" width="3.165" height="0.742" transform="translate(7.175 23.936)" fill="#2a2a29"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Twój pakiet</p>
                        </button> -->
                    </li>
                    <!-- Dopasowanie -->
                    <li
                        v-if="
                            user?.paymentPlus == true ||
                            user?.paymentBasic == true
                        "
                        :class="{ 'active-link': isLinkActive('/panel/match') }"
                    >
                        <RouterLink to="/panel/match">
                            <div class="user-panel__sidebar-pages-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    id="Group_400"
                                    data-name="Group 400"
                                    width="35.758"
                                    height="25.785"
                                    viewBox="0 0 35.758 25.785"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_250"
                                                data-name="Rectangle 250"
                                                width="25.006"
                                                height="22.868"
                                                fill="#2a2a29"
                                                stroke="#2a2a29"
                                                stroke-width="0.2"
                                            />
                                        </clipPath>
                                        <clipPath id="clip-path-2">
                                            <rect
                                                id="Rectangle_250-2"
                                                data-name="Rectangle 250"
                                                width="15.592"
                                                height="14.352"
                                                fill="#2a2a29"
                                                stroke="#2a2a29"
                                                stroke-width="0.2"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_378"
                                        data-name="Group 378"
                                        transform="translate(10.752 0)"
                                    >
                                        <g
                                            id="Group_377"
                                            data-name="Group 377"
                                            transform="translate(0)"
                                        >
                                            <path
                                                id="Path_367"
                                                data-name="Path 367"
                                                d="M11.2,20.284a.368.368,0,0,1-.242-.079C10.605,19.9,2.376,12.671.639,9A6.932,6.932,0,0,1,.39,3.8,5.439,5.439,0,0,1,3.158.653,6.64,6.64,0,0,1,5.975.027a5.889,5.889,0,0,1,5.106,2.657l.122.206.14-.237A5.893,5.893,0,0,1,16.448,0a6.64,6.64,0,0,1,2.819.627,5.436,5.436,0,0,1,2.766,3.149,6.926,6.926,0,0,1-.249,5.194c-1.722,3.641-9.822,10.776-10.3,11.2a.407.407,0,0,1-.231.115.488.488,0,0,1-.053,0M5.975.776a5.893,5.893,0,0,0-2.5.554A4.7,4.7,0,0,0,1.095,4.053a6.172,6.172,0,0,0,.22,4.626C2.822,11.864,9.78,18.157,11.2,19.419c1.342-1.194,8.393-7.56,9.911-10.77a6.16,6.16,0,0,0,.221-4.62A4.7,4.7,0,0,0,18.948,1.3a5.888,5.888,0,0,0-2.5-.555A5.138,5.138,0,0,0,11.988,3.03l-.443.755-.036.054a.374.374,0,0,1-.631-.023l-.444-.755A5.133,5.133,0,0,0,5.975.776"
                                                transform="translate(1.292 1.292)"
                                                fill="#2a2a29"
                                                stroke="#2a2a29"
                                                stroke-width="0.2"
                                            />
                                        </g>
                                    </g>
                                    <g
                                        id="Group_379"
                                        data-name="Group 379"
                                        transform="translate(0 11.433)"
                                    >
                                        <g
                                            id="Group_377-2"
                                            data-name="Group 377"
                                            transform="translate(0 0)"
                                        >
                                            <path
                                                id="Path_367-2"
                                                data-name="Path 367"
                                                d="M6.5,11.768a.213.213,0,0,1-.141-.046c-.2-.177-4.977-4.371-5.985-6.5A4.021,4.021,0,0,1,.226,2.2,3.156,3.156,0,0,1,1.832.379,3.852,3.852,0,0,1,3.466.016,3.417,3.417,0,0,1,6.429,1.557l.071.12.081-.138A3.419,3.419,0,0,1,9.542,0a3.852,3.852,0,0,1,1.636.364,3.154,3.154,0,0,1,1.6,1.827A4.018,4.018,0,0,1,12.638,5.2c-1,2.112-5.7,6.251-5.977,6.5a.236.236,0,0,1-.134.066l-.031,0M3.466.45a3.419,3.419,0,0,0-1.45.322A2.727,2.727,0,0,0,.635,2.351,3.58,3.58,0,0,0,.763,5.035c.874,1.848,4.911,5.5,5.733,6.23.778-.693,4.869-4.386,5.75-6.248a3.573,3.573,0,0,0,.128-2.681A2.724,2.724,0,0,0,10.993.756,3.416,3.416,0,0,0,9.542.434,2.981,2.981,0,0,0,6.955,1.758L6.7,2.2l-.021.031a.217.217,0,0,1-.366-.013l-.257-.438A2.978,2.978,0,0,0,3.466.45"
                                                transform="translate(1.292 1.292)"
                                                fill="#2a2a29"
                                                stroke="#2a2a29"
                                                stroke-width="0.4"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Dopasowanie</p>
                        </RouterLink>
                        <!-- <button>
                            <div class="user-panel__sidebar-pages-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Group_400" data-name="Group 400" width="35.758" height="25.785" viewBox="0 0 35.758 25.785">
                                    <defs>
                                        <clipPath id="clip-path">
                                        <rect id="Rectangle_250" data-name="Rectangle 250" width="25.006" height="22.868" fill="#2a2a29" stroke="#2a2a29" stroke-width="0.2"/>
                                        </clipPath>
                                        <clipPath id="clip-path-2">
                                        <rect id="Rectangle_250-2" data-name="Rectangle 250" width="15.592" height="14.352" fill="#2a2a29" stroke="#2a2a29" stroke-width="0.2"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Group_378" data-name="Group 378" transform="translate(10.752 0)">
                                        <g id="Group_377" data-name="Group 377" transform="translate(0)">
                                        <path id="Path_367" data-name="Path 367" d="M11.2,20.284a.368.368,0,0,1-.242-.079C10.605,19.9,2.376,12.671.639,9A6.932,6.932,0,0,1,.39,3.8,5.439,5.439,0,0,1,3.158.653,6.64,6.64,0,0,1,5.975.027a5.889,5.889,0,0,1,5.106,2.657l.122.206.14-.237A5.893,5.893,0,0,1,16.448,0a6.64,6.64,0,0,1,2.819.627,5.436,5.436,0,0,1,2.766,3.149,6.926,6.926,0,0,1-.249,5.194c-1.722,3.641-9.822,10.776-10.3,11.2a.407.407,0,0,1-.231.115.488.488,0,0,1-.053,0M5.975.776a5.893,5.893,0,0,0-2.5.554A4.7,4.7,0,0,0,1.095,4.053a6.172,6.172,0,0,0,.22,4.626C2.822,11.864,9.78,18.157,11.2,19.419c1.342-1.194,8.393-7.56,9.911-10.77a6.16,6.16,0,0,0,.221-4.62A4.7,4.7,0,0,0,18.948,1.3a5.888,5.888,0,0,0-2.5-.555A5.138,5.138,0,0,0,11.988,3.03l-.443.755-.036.054a.374.374,0,0,1-.631-.023l-.444-.755A5.133,5.133,0,0,0,5.975.776" transform="translate(1.292 1.292)" fill="#2a2a29" stroke="#2a2a29" stroke-width="0.2"/>
                                        </g>
                                    </g>
                                    <g id="Group_379" data-name="Group 379" transform="translate(0 11.433)">
                                        <g id="Group_377-2" data-name="Group 377" transform="translate(0 0)">
                                        <path id="Path_367-2" data-name="Path 367" d="M6.5,11.768a.213.213,0,0,1-.141-.046c-.2-.177-4.977-4.371-5.985-6.5A4.021,4.021,0,0,1,.226,2.2,3.156,3.156,0,0,1,1.832.379,3.852,3.852,0,0,1,3.466.016,3.417,3.417,0,0,1,6.429,1.557l.071.12.081-.138A3.419,3.419,0,0,1,9.542,0a3.852,3.852,0,0,1,1.636.364,3.154,3.154,0,0,1,1.6,1.827A4.018,4.018,0,0,1,12.638,5.2c-1,2.112-5.7,6.251-5.977,6.5a.236.236,0,0,1-.134.066l-.031,0M3.466.45a3.419,3.419,0,0,0-1.45.322A2.727,2.727,0,0,0,.635,2.351,3.58,3.58,0,0,0,.763,5.035c.874,1.848,4.911,5.5,5.733,6.23.778-.693,4.869-4.386,5.75-6.248a3.573,3.573,0,0,0,.128-2.681A2.724,2.724,0,0,0,10.993.756,3.416,3.416,0,0,0,9.542.434,2.981,2.981,0,0,0,6.955,1.758L6.7,2.2l-.021.031a.217.217,0,0,1-.366-.013l-.257-.438A2.978,2.978,0,0,0,3.466.45" transform="translate(1.292 1.292)" fill="#2a2a29" stroke="#2a2a29" stroke-width="0.4"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>Dopasowanie</p>
                        </button>  -->
                    </li>
                    <!-- Rozmowa -->
                    <li
                        v-if="
                            (user?.paymentPlus == true ||
                                user?.paymentBasic == true) &&
                            this.matchStore.areAvailableMatches
                        "
                        :class="{ 'active-link': isLinkActive('/panel/chat') }"
                    >
                        <RouterLink to="/panel/chat">
                            <div class="user-panel__sidebar-pages-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    id="Group_401"
                                    data-name="Group 401"
                                    width="27.844"
                                    height="26.113"
                                    viewBox="0 0 27.844 26.113"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_146"
                                                data-name="Rectangle 146"
                                                width="27.844"
                                                height="26.113"
                                                fill="#2a2a29"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_233"
                                        data-name="Group 233"
                                        transform="translate(0 0)"
                                    >
                                        <path
                                            id="Path_237"
                                            data-name="Path 237"
                                            d="M25.524,0H9.745a2.323,2.323,0,0,0-2.32,2.32V4.177H2.32A2.323,2.323,0,0,0,0,6.5V17.171a2.323,2.323,0,0,0,2.32,2.32H3.87L1.832,26.114l7.132-6.623H18.1a2.323,2.323,0,0,0,2.32-2.32V15.881l6.522,6.056L24.9,15.314h.621a2.323,2.323,0,0,0,2.32-2.32V2.32A2.323,2.323,0,0,0,25.524,0M19.491,17.171A1.394,1.394,0,0,1,18.1,18.563H8.6L3.737,23.078l1.389-4.515H2.32A1.394,1.394,0,0,1,.928,17.171V6.5A1.394,1.394,0,0,1,2.32,5.1H18.1A1.394,1.394,0,0,1,19.491,6.5Zm7.425-4.177a1.394,1.394,0,0,1-1.392,1.392H23.646L25.035,18.9l-4.616-4.287V6.5a2.323,2.323,0,0,0-2.32-2.32H8.353V2.32A1.394,1.394,0,0,1,9.745.928H25.524A1.394,1.394,0,0,1,26.916,2.32Z"
                                            transform="translate(0 0)"
                                            fill="#2a2a29"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <p>Rozmowa</p>
                        </RouterLink>
                    </li>
                </ul>
            </aside>
        </div>
        <div class="wrapper">
            <div v-if="showInstallPrompt" class="modal">
                <div class="install-on-ios">
                    <h3>Zainstaluj aplikację</h3>
                    <p>
                        Aby lepiej korzystać z aplikacji, zainstaluj ją na swoim
                        urządzeniu iOS. W Safari, kliknij ikonę "Udostępnij" i
                        wybierz "Dodaj do ekranu głównego".
                    </p>
                    <button @click="closeModal">Zamknij</button>
                </div>
            </div>
            <router-view />
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
    import axios from 'axios'
    import HeaderComponent from '@/components/landing-03/HeaderComponent.vue'
    import Footer from '@/components/landing-03/footer.vue'

    import { mapStores } from 'pinia'
    import { useMatchStore } from '@/stores/match.js'
    import { useUserStore } from '@/stores/user.js'
    import { useTemporaryMatchStore } from '@/stores/temporaryMatch'
    import { useNotificationStore } from '@/stores/notification.js'

    import { messaging } from '@/utils/firebase'
    import { getToken } from 'firebase/messaging'

    const VAPID_KEY = process.env.VUE_APP_PUBLIC_VAPID_KEY

    export default {
        setup() {
            const userStore = useUserStore()
            const notificationStore = useNotificationStore()
            const temporaryMatchStore = useTemporaryMatchStore()
            return { userStore, notificationStore, temporaryMatchStore }
        },
        data() {
            return {
                show: false,
                userId: '',
                announcements: [],
                announcementsSaw: [],
                renderComponent: 0,
                questionnaire: null,
                token: null,
                showInstallPrompt: false,
                permissions: null,
                serviceWorkerRegistration: null,
                serviceWorkerCount: null
            }
        },
        watch: {
            'notificationStore.permissions'(newVal, oldVal) {
                console.log('Permissions changed:', newVal)

                if (newVal === 'granted') {
                    console.log('permissions granted... sending token')
                    this.requestPermissionAndToken()
                }
            },
            $route(to, from) {
                this.checkMatches()
            }
        },
        components: {
            Footer,
            HeaderComponent
        },
        methods: {
            updateApp() {
                if (this.registration && this.registration.waiting) {
                    this.registration.waiting.postMessage({
                        type: 'SKIP_WAITING'
                    })
                }
            },
            async unregisterOldServiceWorkers() {
                const urlsToUnregister = ['service-worker.js', 'sw.js']

                navigator.serviceWorker
                    .getRegistrations()
                    .then(registrations => {
                        registrations.forEach(registration => {
                            if (
                                registration.active &&
                                urlsToUnregister.includes(
                                    registration.active.scriptURL
                                )
                            ) {
                                registration.unregister().then(success => {
                                    if (success) {
                                        console.log(
                                            `Service worker z ${registration.active.scriptURL} został wyrejestrowany.`
                                        )
                                    } else {
                                        console.log(
                                            `Nie udało się wyrejestrować service workera z ${registration.active.scriptURL}.`
                                        )
                                    }
                                })
                            }
                        })
                    })

                navigator.serviceWorker
                    .getRegistrations()
                    .then(registrations => {
                        this.serviceWorkerCount = registrations.length
                    })
            },
            unregisterServiceWorkerIfIsRegisteredTwice() {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker
                        .getRegistrations()
                        .then(function (registrations) {
                            if (registrations.length > 1) {
                                registrations.forEach(function (
                                    registration,
                                    index
                                ) {
                                    if (index > 0) {
                                        registration
                                            .unregister()
                                            .then(function (success) {
                                                if (success) {
                                                    console.log(
                                                        `Service Worker ${index} unregistered successfully.`
                                                    )
                                                } else {
                                                    console.log(
                                                        `Failed to unregister Service Worker ${index}.`
                                                    )
                                                }
                                            })
                                    }
                                })
                            } else {
                                console.log(
                                    'No duplicate service workers found.'
                                )
                            }
                        })
                        .catch(function (error) {
                            console.error(
                                'Error fetching service worker registrations:',
                                error
                            )
                        })
                }
            },
            isIOS() {
                return /iPhone|iPad|iPod/i.test(navigator.userAgent)
            },
            isSafari() {
                return (
                    /Safari/i.test(navigator.userAgent) &&
                    !/Chrome/i.test(navigator.userAgent)
                )
            },
            async registerPushNotifications() {
                try {
                    if (!('serviceWorker' in navigator)) {
                        throw new Error(
                            'Service workers are not supported in this browser'
                        )
                    }

                    // Register the service worker
                    const registration = await navigator.serviceWorker.register(
                        '/firebase-messaging-sw.js'
                    )
                    console.log(
                        'Service Worker registered successfully:',
                        registration
                    )

                    // Wait for the service worker to be ready and active
                    await navigator.serviceWorker.ready

                    // Ensure the service worker is active
                    if (registration.active) {
                        // Request permission from the user
                        const permission =
                            await Notification.requestPermission()
                        if (permission !== 'granted') {
                            console.log('Notification permission denied')
                            return
                        }

                        // Get the FCM token
                        const currentToken = await getToken(messaging, {
                            vapidKey: VAPID_KEY,
                            serviceWorkerRegistration: registration
                        })

                        if (currentToken) {
                            console.log('FCM registration token:', currentToken)
                            // Send this token to your server
                            await this.sendTokenToServer(currentToken)
                        } else {
                            console.log(
                                'No registration token available. Request permission to generate one.'
                            )
                        }
                    } else {
                        throw new Error('Service Worker is not active')
                    }
                } catch (error) {
                    console.error(
                        'Error during registration or getting token:',
                        error
                    )
                }
            },
            async sendTokenToServer(token) {
                try {
                    console.log('Sending token to backend:', token)

                    const authToken = this.getCookie('authToken')
                    const config = {
                        headers: { Authorization: `Bearer ${authToken}` }
                    }
                    const userId = localStorage.getItem('userId')

                    console.log(userId)
                    console.log(token)

                    const response = await axios.post(
                        `${this.url}api/push/register-fcm-token`,
                        { fcmToken: token, userId: userId },
                        config
                    )

                    console.log('Response from backend:', response.data)
                } catch (e) {
                    console.error('Error sending token to backend:', e)
                }
            },
            isLinkActive(path) {
                return this.$route.path.startsWith(path)
            },
            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            getAnnouncement() {
                let id = window.localStorage.getItem('userId')
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json'
                    }
                }

                try {
                    axios
                        .get(
                            `${this.url}api/announcement/single-user/${id}`,
                            config
                        )
                        .then(res => {
                            this.announcements = res.data
                        })
                } catch (error) {
                    console.log(error)
                }
            },
            hideAnnouncement(announcementId, userId) {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                const data = {
                    userId: userId,
                    announcementId: announcementId,
                    closed: true
                }

                try {
                    axios
                        .post(
                            `${this.url}api/announcement-saw/send`,
                            data,
                            config
                        )
                        .then(resp => {
                            // console.log(resp)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                } catch (error) {
                    console.log(error)
                }
            },
            async checkMatches() {
                await this.matchStore.checkMatches()
            }
        },
        computed: {
            ...mapStores(useMatchStore),
            user() {
                return this.userStore.user
            },
            hasQuestionnaireFilled() {
                return (
                    !!this.questionnaire?.wantedGender?.length &&
                    !!this.questionnaire?.cityPossible?.length
                )
            }
        },
        mounted() {
            this.userStore.getUser()
            this.userStore.getUserQuestionnaire()
            this.temporaryMatchStore.getTemporaryMatch()
            this.getAnnouncement()
            this.checkMatches()
            this.unregisterServiceWorkerIfIsRegisteredTwice()
            this.unregisterOldServiceWorkers()
            this.registerPushNotifications()
        }
    }
</script>
<style lang="scss">
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .user-panel {
        min-height: 100vh;
        background-color: #f6f4f0;

        &-icon--questionnaire {
            polyline,
            polygon,
            line {
                stroke: #111111;
            }
        }

        .link-active {
            background-color: $pink;
            color: $white;
            @include breakpoint-max('xs-tablet') {
                color: $black;
            }
        }

        .active-link {
            svg {
                path,
                rect {
                    fill: $white;
                }
            }
            #Group_378,
            #Group_379 {
                path,
                rect {
                    fill: $white;
                    stroke: $white;
                }
            }
            p {
                @include breakpoint-max('xs-tablet') {
                    color: $black !important;
                }
            }
            @include breakpoint-max('xs-tablet') {
                background-color: transparent !important;
            }
            .user-panel__sidebar-pages-icon {
                @include breakpoint-max('xs-tablet') {
                    background-color: $pink !important;
                    box-shadow: 0px 3px 6px #00000029;
                }
            }
        }
        &__logout {
            padding: 12px 25px;
            cursor: pointer;
            border-radius: 10px;
            background-color: #f2545b;
            color: #fff;
        }
        &__header {
            height: 104px;
            background-color: $white;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            padding-left: 65px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include breakpoint-max('xs-tablet') {
                height: 50px;
            }
        }
        &__header-logo {
            display: flex;
            align-items: center;
            svg {
                margin-right: 14px;
            }
            p {
                font-family: 'NunitoExtraBold';
                font-weight: 800;
                font-size: 2rem;
                line-height: 3.7rem;
                color: $black;
            }
        }
        &__btn {
            background-color: #0fb70d;
            color: #fff;
            text-decoration: none;
            padding: 15px 20px;
            border-radius: 5px;
            margin-top: 30px;
            display: inline-block;
            margin-bottom: 20px;
            &--red {
                background-color: #f2545b;
                margin-right: 10px;
            }
        }
        &__header-notification {
            cursor: pointer;
            width: 53px;
            height: 53px;
            background: $gray;
            border-radius: 15px;
            display: grid;
            place-items: center;
            position: relative;
            transition: 0.3s;
            &:hover {
                svg {
                    animation: myAnim 2s ease 0s 1 normal forwards;
                }
            }
        }
        &__header-notification-circle {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 12px;
            height: 12px;
            background-color: #f2545b;
            border-radius: 50%;
        }
        &__wrapper {
            display: flex;
        }
        &__sidebar {
            max-width: 376px;
            width: 100%;
            position: fixed;
            height: 100vh;
            top: 0;
            // background: $gray;
            border-radius: 0 15px 15px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 177px;
            padding-bottom: 150px;
            @include breakpoint-max('v-large') {
                max-width: 230px;
            }
            @include breakpoint-max('xs-tablet') {
                position: static;
                max-width: none;
                padding-top: 70px;
                padding-bottom: 0;
                height: auto;
            }
        }
        &__sidebar-pages {
            width: 100%;
            display: flex;
            flex-direction: column;
            list-style: none;
            row-gap: 14px;
            li {
                background-color: $bege3;
                border-radius: 0px 10px 10px 0px;
                box-shadow: 0px 3px 6px #00000029;
                display: flex;
                align-items: center;
                picture {
                    display: block;
                    img {
                        display: block;
                        width: 36px;
                        height: 36px;
                        object-fit: cover;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        border: 1px solid $white;
                        @include breakpoint-max('xs-tablet') {
                            width: 43px;
                            height: 43px;
                        }
                    }
                }
                a,
                button {
                    padding-left: 52px;
                    column-gap: 16px;
                    text-decoration: none;
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 3rem;
                    color: $black1;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 57px;
                    background: transparent;
                    @include breakpoint-max('v-large') {
                        padding-left: 30px;
                    }
                    @include breakpoint-max('xs-tablet') {
                        flex-direction: column;
                        padding: 0;
                        height: auto;
                    }
                    @include breakpoint-max('mobile') {
                        font-size: 1rem;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                svg {
                    margin-right: 13px;
                }
                @include breakpoint-max('xs-tablet') {
                    width: fit-content;
                    background: transparent;
                    box-shadow: none;
                }
            }
            @include breakpoint-max('xs-tablet') {
                flex-direction: row;
                justify-content: center;
                column-gap: 40px;
            }
            @include breakpoint-max('mobile') {
                column-gap: 10px;
            }
        }
        &__sidebar-pages-icon {
            width: 36px;
            min-width: 36px;
            display: grid;
            place-items: center;
            svg {
                margin: 0 !important;
            }
            @include breakpoint-max('xs-tablet') {
                width: 70px;
                height: 70px;
                border-radius: 10px;
                background-color: #e1dbd5;
            }
            @include breakpoint-max('s-mobile') {
                width: 55px;
                height: 55px;
            }
        }
        &__sidebar-nav {
            position: absolute;
            left: -19px;
            top: 0px;
            width: 4px;
            height: 38px;
            background-color: #f2545b;
            box-shadow: -3px 3px 6px rgba(242, 84, 91, 0.35);
            border-radius: 500px;
            transition: 0.3s;
        }
        &__sidebar-logout {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__sidebar-line {
            margin-bottom: 34px !important;
            max-width: 239px;
            margin: 0 auto;
            width: 100%;
            height: 1px;
            background-color: $bege;
        }
        &__sidebar-logout-wrapper {
            display: flex;
            align-items: center;
            cursor: pointer;
            p {
                font-family: 'NunitoMedium';
                font-weight: 500;
                font-size: 1.8rem;
                line-height: 2.5rem;
                color: $black1;
            }
            &:hover {
                .arrow {
                    left: 12px;
                }
            }
        }
        &__sidebar-logout-svg {
            position: relative;
            width: 17px;
            height: 19px;
            margin-right: 15px;
            .left {
                position: absolute;
                top: 0px;
                left: 0px;
            }
            .arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 6px;
                transition: 0.3s;
            }
        }
        &__content {
            width: 100%;
            height: calc(100vh - 104px);
            // background-image: url(http://localhost:8080/img/light-bcg-big.93d5f768.png);
            input {
                padding-left: 93px;
                width: 270px;
                padding: 15px;
            }
        }
        &__content-users {
            padding-top: 49px;
            padding-left: 93px;
            padding-top: 13px;
            max-width: 1294px;
            width: 100%;
        }
        &__content-input {
            padding-left: 93px;
            input {
                background: $gray;
                border-radius: 10px;
                outline: none;
                border: none;
                font-family: 'NunitoBold';
                font-weight: 700;
                font-size: 1.6rem;
                color: #111111;
            }
        }
        &__content-input-wrapper {
            position: relative;
            width: fit-content;
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 24px;
            }
        }

        .wrapper {
            min-height: 100vh;
            width: 100%;
            padding-top: 177px;
            padding-left: 469px;
            background-color: #f6f4f0 !important;
            @include breakpoint-max('v-large') {
                padding-left: 260px;
            }
            @include breakpoint-max('xs-tablet') {
                padding: 0;
                padding-top: 40px;
                min-height: auto;
                height: 100% !important;
                min-height: calc(100vh - 164px - 246px);
            }
            @include breakpoint-max('mobile') {
                min-height: calc(100vh - 164px - 106px);
            }
            @include breakpoint-max('s-mobile') {
                min-height: calc(100vh - 148px - 106px);
            }
        }
    }
</style>
