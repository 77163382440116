<template>
    <div ref="el" :class="[{ chatResized: chatResized }, 'match-chat']">
        <PopUp
            title="Czy na pewno chcesz zrezygnować z dalszej rozmowy ?"
            content=" Klikając na TAK użytkownik otrzyma powiadomienie o Twojej rezygnacji z
        dalszej rozmowy. Operacji nie da się cofnąć a w przyszłości nie będziemy
        parowali Ciebie z tym użytkownikiem."
            :show="showPopup"
            @approve="
                () => {
                    rejectMatch()
                    showPopup = false
                }
            "
            @decline="
                () => {
                    showPopup = false
                }
            "
        />
        <PopUp
            title="Czy na pewno chcesz zgłosić użytkownika oraz zrezygnować z dalszej rozmowy ?"
            content="Klikając na TAK match zostanie odrzucony. Zgłoszenie zostanie przez nas zweryfikowane pod kątem nie przestrzgania regulaminu. <br/><br/>Traktujemy takie zgłoszenia niezwykle poważnie"
            :show="showReportPopup"
            :pending="loading"
            @approve="sendReport()"
            @decline="
                () => {
                    showReportPopup = false
                }
            "
        >
            <template v-slot:loader>
                <Loader v-if="loading" :showLabel="true" label="Wysyłanie..." />
            </template>
            <template v-slot:response></template>
            <template v-if="error_msg" v-slot:err>
                {{ error_msg }}
            </template>
        </PopUp>
        <h1 class="match-chat__title">Porozmawiajmy</h1>

        <div class="match-chat__wrapper">
            <div class="match-chat__left">
                <div class="match-chat__left-box">
                    <div
                        @click="resizeChat"
                        class="match-chat__left-box-svg-circle"
                    >
                        <svg
                            class="desktop"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="22.976"
                            height="21.547"
                            viewBox="0 0 22.976 21.547"
                        >
                            <defs>
                                <clipPath id="clip-path">
                                    <rect
                                        id="Rectangle_146"
                                        data-name="Rectangle 146"
                                        width="22.976"
                                        height="21.547"
                                        fill="#fff"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="Group_391"
                                data-name="Group 391"
                                transform="translate(0 -0.001)"
                            >
                                <g
                                    id="Group_233"
                                    data-name="Group 233"
                                    transform="translate(0 0.001)"
                                >
                                    <path
                                        id="Path_237"
                                        data-name="Path 237"
                                        d="M21.061,0H8.041A1.917,1.917,0,0,0,6.127,1.915V3.446H1.915A1.917,1.917,0,0,0,0,5.361v8.807a1.917,1.917,0,0,0,1.915,1.915H3.193L1.511,21.548,7.4,16.083h7.538a1.917,1.917,0,0,0,1.915-1.915V13.1l5.381,5-1.682-5.465h.513a1.917,1.917,0,0,0,1.915-1.915V1.915A1.917,1.917,0,0,0,21.061,0M16.083,14.168a1.15,1.15,0,0,1-1.149,1.149H7.1L3.084,19.043,4.23,15.317H1.915A1.15,1.15,0,0,1,.766,14.168V5.361A1.15,1.15,0,0,1,1.915,4.212H14.934a1.15,1.15,0,0,1,1.149,1.149Zm6.127-3.446a1.15,1.15,0,0,1-1.149,1.149H19.512L20.658,15.6l-3.809-3.537v-6.7a1.917,1.917,0,0,0-1.915-1.915H6.893V1.915A1.15,1.15,0,0,1,8.041.766H21.061A1.15,1.15,0,0,1,22.21,1.915Z"
                                        transform="translate(0 0)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg>
                        <svg
                            class="mobile"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <path
                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                                fill="#FFF"
                            />
                        </svg>
                    </div>
                    <div
                        class="match-chat__left-box-messages"
                        ref="messageBox"
                        @scroll.passive="handleScroll"
                    >
                        <div class="match-chat__left-box-messages-content">
                            <div
                                v-for="message in messages"
                                :class="[
                                    {
                                        'my-message':
                                            message.fromUserId != getUserId
                                    },
                                    'message'
                                ]"
                            >
                                <!-- <div class="cards-message" v-if="message.card">
                                    <div class="cards-message-ask">
                                        <p>Otwarte karty</p>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25.906"
                                            height="25.204"
                                            viewBox="0 0 25.906 25.204"
                                        >
                                            <g
                                                id="Group_319"
                                                data-name="Group 319"
                                                transform="translate(-106.695 -530.634)"
                                            >
                                                <g
                                                    id="Rectangle_152"
                                                    data-name="Rectangle 152"
                                                    transform="translate(106.695 537.003) rotate(-16)"
                                                    fill="black"
                                                    stroke="black"
                                                    stroke-width="1"
                                                >
                                                    <rect
                                                        width="13.063"
                                                        height="19.594"
                                                        rx="3"
                                                        stroke="none"
                                                    />
                                                    <rect
                                                        x="0.5"
                                                        y="0.5"
                                                        width="12.063"
                                                        height="18.594"
                                                        rx="2.5"
                                                        fill="#F4F2EE"
                                                    />
                                                </g>
                                                <g
                                                    id="Rectangle_153"
                                                    data-name="Rectangle 153"
                                                    transform="translate(120.49 530.634) rotate(22)"
                                                    fill="black"
                                                    stroke="black"
                                                    stroke-width="1"
                                                >
                                                    <rect
                                                        width="13.063"
                                                        height="19.594"
                                                        rx="3"
                                                        stroke="none"
                                                    />
                                                    <rect
                                                        x="0.5"
                                                        y="0.5"
                                                        width="12.063"
                                                        height="18.594"
                                                        rx="2.5"
                                                        fill="#F4F2EE"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <p v-if="message.isAnswear" class="ask">
                                        Lubisz psy czy koty?
                                    </p>
                                </div> -->
                                <div class="message__wrapper">
                                    <div class="message__date">
                                        {{ formattedDate(message.date) }}
                                    </div>
                                    <div class="message__content">
                                        <p
                                            v-if="message.isCard"
                                            class="message__cards"
                                        >
                                            <span>Otwarte karty</span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="25.906"
                                                height="25.204"
                                                viewBox="0 0 25.906 25.204"
                                            >
                                                <g
                                                    id="Group_319"
                                                    data-name="Group 319"
                                                    transform="translate(-106.695 -530.634)"
                                                >
                                                    <g
                                                        id="Rectangle_152"
                                                        data-name="Rectangle 152"
                                                        transform="translate(106.695 537.003) rotate(-16)"
                                                        fill="#FFF"
                                                        stroke="#000"
                                                        stroke-width="1"
                                                    >
                                                        <rect
                                                            width="13.063"
                                                            height="19.594"
                                                            rx="3"
                                                            stroke="none"
                                                        />
                                                        <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="12.063"
                                                            height="18.594"
                                                            rx="2.5"
                                                            fill="none"
                                                        />
                                                    </g>
                                                    <g
                                                        id="Rectangle_153"
                                                        data-name="Rectangle 153"
                                                        transform="translate(120.49 530.634) rotate(22)"
                                                        fill="#FFF"
                                                        stroke="#000"
                                                        stroke-width="1"
                                                    >
                                                        <rect
                                                            width="13.063"
                                                            height="19.594"
                                                            rx="3"
                                                            stroke="none"
                                                        />
                                                        <rect
                                                            x="0.5"
                                                            y="0.5"
                                                            width="12.063"
                                                            height="18.594"
                                                            rx="2.5"
                                                            fill="none"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </p>
                                        {{ message.message }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="match-chat__left-box-footer">
                        <SendMessage
                            @sendMessage="sendMessage"
                            @sendCardMessage="sendCardMessage"
                        />
                    </div>
                    <div
                        class="match-chat__left-box-cards"
                        style="display: none"
                    >
                        <div class="match-chat__left-box-cards-title">
                            <div
                                class="match-chat__left-box-cards-title-circle"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25.906"
                                    height="25.204"
                                    viewBox="0 0 25.906 25.204"
                                >
                                    <g
                                        id="Group_319"
                                        data-name="Group 319"
                                        transform="translate(-106.695 -530.634)"
                                    >
                                        <g
                                            id="Rectangle_152"
                                            data-name="Rectangle 152"
                                            transform="translate(106.695 537.003) rotate(-16)"
                                            fill="#d13724"
                                            stroke="#fff"
                                            stroke-width="1"
                                        >
                                            <rect
                                                width="13.063"
                                                height="19.594"
                                                rx="3"
                                                stroke="none"
                                            />
                                            <rect
                                                x="0.5"
                                                y="0.5"
                                                width="12.063"
                                                height="18.594"
                                                rx="2.5"
                                                fill="none"
                                            />
                                        </g>
                                        <g
                                            id="Rectangle_153"
                                            data-name="Rectangle 153"
                                            transform="translate(120.49 530.634) rotate(22)"
                                            fill="#d13724"
                                            stroke="#fff"
                                            stroke-width="1"
                                        >
                                            <rect
                                                width="13.063"
                                                height="19.594"
                                                rx="3"
                                                stroke="none"
                                            />
                                            <rect
                                                x="0.5"
                                                y="0.5"
                                                width="12.063"
                                                height="18.594"
                                                rx="2.5"
                                                fill="none"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <p>OTWARTE KARTY</p>
                        </div>
                        <div class="match-chat__left-box-cards-content">
                            <p>Wolisz koty czy psy?</p>
                            <div
                                class="match-chat__left-box-cards-content-buttons"
                            >
                                <button>Wyślij</button>
                                <button>Losuj dalej</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="match-chat__right">
                <div class="match-chat__right-top">
                    <picture>
                        <img :src="profile?.user.photo1" alt="" />
                    </picture>
                    <div class="match-chat__right-top-name">
                        <p>{{ profile?.questionnaire?.name }}</p>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="22.976"
                            height="21.547"
                            viewBox="0 0 22.976 21.547"
                        >
                            <defs>
                                <clipPath id="clip-path">
                                    <rect
                                        id="Rectangle_146"
                                        data-name="Rectangle 146"
                                        width="22.976"
                                        height="21.547"
                                        fill="#fff"
                                    />
                                </clipPath>
                            </defs>
                            <g
                                id="Group_391"
                                data-name="Group 391"
                                transform="translate(0 -0.001)"
                            >
                                <g
                                    id="Group_233"
                                    data-name="Group 233"
                                    transform="translate(0 0.001)"
                                >
                                    <path
                                        id="Path_237"
                                        data-name="Path 237"
                                        d="M21.061,0H8.041A1.917,1.917,0,0,0,6.127,1.915V3.446H1.915A1.917,1.917,0,0,0,0,5.361v8.807a1.917,1.917,0,0,0,1.915,1.915H3.193L1.511,21.548,7.4,16.083h7.538a1.917,1.917,0,0,0,1.915-1.915V13.1l5.381,5-1.682-5.465h.513a1.917,1.917,0,0,0,1.915-1.915V1.915A1.917,1.917,0,0,0,21.061,0M16.083,14.168a1.15,1.15,0,0,1-1.149,1.149H7.1L3.084,19.043,4.23,15.317H1.915A1.15,1.15,0,0,1,.766,14.168V5.361A1.15,1.15,0,0,1,1.915,4.212H14.934a1.15,1.15,0,0,1,1.149,1.149Zm6.127-3.446a1.15,1.15,0,0,1-1.149,1.149H19.512L20.658,15.6l-3.809-3.537v-6.7a1.917,1.917,0,0,0-1.915-1.915H6.893V1.915A1.15,1.15,0,0,1,8.041.766H21.061A1.15,1.15,0,0,1,22.21,1.915Z"
                                        transform="translate(0 0)"
                                        fill="#fff"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div class="match-chat__right-bottom">
                    <button @click="() => (this.showPopup = true)">
                        REZYGNUJ
                    </button>
                    <button
                        style="display: none"
                        @click="() => (this.showReportPopup = true)"
                    >
                        ZGŁOŚ NARUSZENIE
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import io from 'socket.io-client'
    import PopUp from '@/components/atoms/PopUp.vue'
    import Loader from '@/components/atoms/Loader.vue'
    import { useReport } from '@/composables/report'
    import SendMessage from '@/components/chat/SendMessage.vue'
    import UserProfileNotificaton from '@/components/notifications/UserProfileNotification.vue'

    import noEmojis from '../directives/noEmojis'

    import { mapStores } from 'pinia'
    import { useMatchStore } from '@/stores/match.js'

    export default {
        directives: {
            noEmojis
        },
        data() {
            return {
                isPwa: true,
                pwaModal: true,
                messages: [],
                message: '',
                lastMessageDate: null,
                loadedNewlyMessages: null,
                currentScrollPosition: null,
                scrollDirection: null,
                socket: io(this.url),
                userId: null,
                secondUser: null,
                profile: null,
                matchDetails: null,
                newMessages: false,
                skip: 0,
                pageSize: 30,
                showPopup: false,
                showReportPopup: false,
                showReportPopupIsPending: false,
                chatResized: false
                /*
                 *  page size 30 to minimum, w przeciwnym razie user w chacie nie będzie mial scrollbara widocznego więc nie będzie mógł scrollować a to oznacza,że nie odczyta starszych wiadomości
                 *
                 */
            }
        },
        components: {
            PopUp,
            Loader,
            SendMessage,
            UserProfileNotificaton
        },
        computed: {
            ...mapStores(useMatchStore),
            getUserId() {
                return window.localStorage.getItem('userId')
            }
        },

        created() {
            this.isPwa =
                window.matchMedia('(display-mode: standalone)').matches ||
                window.navigator.standalone
        },
        updated() {
            this.scrollToBottom()
        },
        mounted() {
            this.scrollToBottom()
            this.setUserID()
            this.getMainUser()
            this.checkMatch()
            // this.handleAutoGrow()
            this.socket.on('newCommentAdded', () => {
                this.getMessages()
            })
            // this.socket.on('getPrevMessages', () => {
            //   console.log('getPrevMessages');
            //   //   this.getPrevMessages();
            // });
            this.socket.on('newMessageAdded', () => {
                this.getMessages()
            })

            window.addEventListener('load', () => {
                setTimeout(() => {
                    this.runMessageGroup()
                }, 300)
            })
            window.addEventListener('DOMContentLoaded', () => {
                setTimeout(() => {
                    this.runMessageGroup()
                }, 800)
            })
        },
        methods: {
            scrollToBottom() {
                this.$nextTick(() => {
                    const messageBox = this.$refs.messageBox
                    if (messageBox) {
                        messageBox.scrollTop = messageBox.scrollHeight
                    }
                })
            },
            setUserID() {
                this.userId = window.localStorage.getItem('userId')
            },
            formattedDate(val) {
                const date = new Date(val)

                // Konwersja do UTC
                const utcYear = date.getUTCFullYear()
                const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0')
                const utcDay = String(date.getUTCDate()).padStart(2, '0')
                const utcHours = String(date.getUTCHours()).padStart(2, '0')
                const utcMinutes = String(date.getUTCMinutes()).padStart(2, '0')
                const utcSeconds = String(date.getUTCSeconds()).padStart(2, '0')

                const formattedDate = `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`
                return formattedDate
            },

            async rejectMatch() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                try {
                    await Promise.all([
                        // 1. reject temporary match
                        await axios
                            .post(
                                `${this.url}api/temporarymatch/reject/${this.secondUser}`,
                                {
                                    rejectingID: this.getUserId
                                },
                                config
                            )
                            .catch(e => console.log(e)),

                        // 2. reject match
                        await axios
                            .put(
                                `${this.url}api/match/reject`,
                                {
                                    firstuser: this.getUserId,
                                    seconduser: this.secondUser,
                                    rejectedBy: this.getUserId
                                },
                                config
                            )
                            .catch(e => console.log(e))
                    ])

                    this.$router.push({
                        name: 'panel profile'
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            // async reportUser(reportFrom, userID, message) {
            //   console.log('report user')
            //   this.showReportPopupIsPending = true
            // },
            scrollChatToEnd() {
                const messageBox = this.$refs.messageBox

                messageBox.scrollTo({
                    top: messageBox.scrollHeight,
                    left: 0,
                    behavior: 'instant'
                })
            },
            handleScroll() {
                const messageBox = this.$refs.messageBox
                const scrollTop = messageBox.scrollTop

                this.currentScrollPosition = messageBox.scrollTop

                if (this.currentScrollPosition > this.previousScrollPosition) {
                    this.scrollDirection = 'down'
                } else if (
                    this.currentScrollPosition < this.previousScrollPosition
                ) {
                    this.scrollDirection = 'up'
                }

                this.previousScrollPosition = this.currentScrollPosition

                this.socket.emit('newMessageAdded')

                if (scrollTop === 0 && this.scrollDirection === 'up') {
                    this.getPrevMessages()
                }
            },
            messageGroup(messClass, elClass, prev, next) {
                const messes = [
                    ...document.querySelectorAll(
                        `.conversation-view__content-message-${messClass}`
                    )
                ]
                messes.forEach(el => {
                    el.parentElement.classList.remove('pL', 'nL', 'pR', 'nR')
                    el.parentElement.previousSibling.firstChild &&
                    !el.parentElement.previousSibling.firstChild.classList.contains(
                        `conversation-view__content-message-${elClass}`
                    )
                        ? el.parentElement.classList.add([prev])
                        : el.parentElement.classList.remove([prev])
                    el.parentElement.nextSibling.firstChild &&
                    !el.parentElement.nextSibling.firstChild.classList.contains(
                        `conversation-view__content-message-${elClass}`
                    )
                        ? el.parentElement.classList.add([next])
                        : el.parentElement.classList.remove([next])
                })
            },
            runMessageGroup() {
                this.messageGroup('left', 'right', 'pL', 'nL')
                this.messageGroup('right', 'left', 'pR', 'nR')
            },
            sendMessage(msg, isCard = false) {
                // Bearer token included ✅
                this.message = msg
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                if (this.message.length == 0) return

                let userId = window.localStorage.getItem('userId')
                const currentDate = new Date()
                currentDate.setHours(currentDate.getHours() + 2)

                let data = {
                    text: this.message,
                    fromUserId: userId,
                    toUserId: this.secondUser,
                    date: currentDate
                }

                if (isCard) {
                    data.isCard = true
                }

                axios
                    .post(`${this.url}api/comments/`, data, config)
                    .then(() => {
                        this.message = ''
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            getMessages() {
                // Bearer token included ✅
                let userId = window.localStorage.getItem('userId')
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }
                axios
                    .get(
                        `${this.url}api/comments/${userId}/${this.secondUser}?limit=${this.pageSize}`,
                        config
                    )
                    .then(resp => {
                        this.messages = resp?.data.reverse()
                        //   this.messages = resp?.data;
                        this.lastMessageDate = this.messages[0].date
                    })
                    .catch(err => {
                        console.error(err)
                    })
            },
            getPrevMessages() {
                // Bearer token included ✅
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                let userId = window.localStorage.getItem('userId')

                axios
                    .post(
                        `${this.url}api/comments/get-prev-messages`,
                        {
                            date: this.lastMessageDate,
                            firstuser: userId,
                            seconduser: this.secondUser
                        },
                        config
                    )
                    .then(resp => {
                        this.loadedNewlyMessages = resp.data
                        this.lastMessageDate =
                            resp.data[resp.data.length - 1].date
                        this.messages = [
                            ...this.loadedNewlyMessages,
                            ...this.messages
                        ]
                        const messageBox = this.$refs.messageBox

                        messageBox.scrollTo({
                            top: 1,
                            left: 0,
                            behavior: 'instant'
                        })
                    })
                    .catch(err => {
                        // console.log(err);
                    })
            },
            getProfile() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/${this.secondUser}`, config)
                    .then(resp => {
                        this.profile = resp.data
                    })
                    .catch(err => {
                        // console.log(err);
                    })
            },
            getMainUser() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                axios
                    .get(`${this.url}api/users/${this.userId}`, config)
                    .then(resp => {
                        this.mainProfile = resp.data
                        // console.log(this.mainProfile)
                    })
                    .catch(err => {
                        // console.log(err);
                    })
            },

            getCookie(name) {
                const value = `; ${document.cookie}`
                const parts = value.split(`; ${name}=`)
                if (parts.length === 2) {
                    return parts.pop().split(';').shift()
                }
            },
            checkMatch() {
                const authToken = this.getCookie('authToken')
                const config = {
                    headers: { Authorization: `Bearer ${authToken}` }
                }

                let id = window.localStorage.getItem('userId')
                axios
                    .get(`${this.url}api/match/user/${id}`, config)
                    .then(resp => {
                        // console.log(resp.data)

                        if (resp.data?.isRejected) {
                            return
                        } else {
                            if (resp.data.secondUserId == id) {
                                this.secondUser = resp.data.userId
                            } else {
                                this.secondUser = resp.data.secondUserId
                            }
                            this.matchDetails = resp.data

                            this.getMessages()
                            this.getProfile()
                        }
                    })
                    .catch(err => {
                        // console.log(err);
                        this.$router.push('/panel/match')
                    })
            },
            resizeChat() {
                this.chatResized
                    ? (this.chatResized = false)
                    : (this.chatResized = true)
            },
            handleAutoGrow() {
                // Targets all textareas with class "txta"
                const textarea = this.$refs.textareaAutogrow
                // console.log(textarea)
                // console.log(this.message)
                let textareas = document.querySelectorAll('.txta'),
                    hiddenDiv = document.createElement('div'),
                    content = null

                textarea.style.resize = 'none'
                // Adds a class to all textareas
                for (let j of textareas) {
                    j.classList.add('txtstuff')
                }

                // Build the hidden div's attributes

                // The line below is needed if you move the style lines to CSS
                // hiddenDiv.classList.add('hiddendiv');

                // Add the "txta" styles, which are common to both textarea and hiddendiv
                // If you want, you can remove those from CSS and add them via JS

                // Add the styles for the hidden div
                // These can be in the CSS, just remove these three lines and uncomment the CSS
                hiddenDiv.style.display = 'none'
                hiddenDiv.style.whiteSpace = 'pre-wrap'
                hiddenDiv.style.wordWrap = 'break-word'

                // Loop through all the textareas and add the event listener
                for (let i of textareas) {
                    ;(function (i) {
                        // Note: Use 'keyup' instead of 'input'
                        // if you want older IE support
                        i.addEventListener('input', function () {
                            // Append hiddendiv to parent of textarea, so the size is correct
                            i.parentNode.appendChild(hiddenDiv)

                            // Remove this if you want the user to be able to resize it in modern browsers
                            i.style.resize = 'none'

                            // This removes scrollbars
                            i.style.overflow = 'hidden'

                            // Every input/change, grab the content
                            content = i.value

                            // Add the same content to the hidden div

                            // This is for old IE
                            content = content.replace(/\n/g, '<br>')

                            // The <br ..> part is for old IE
                            // This also fixes the jumpy way the textarea grows if line-height isn't included
                            hiddenDiv.innerHTML =
                                content + '<br style="line-height: 3px;">'

                            // Briefly make the hidden div block but invisible
                            // This is in order to read the height
                            hiddenDiv.style.visibility = 'hidden'
                            hiddenDiv.style.display = 'block'
                            // i.style.height = hiddenDiv.offsetHeight + 'px'

                            // Make the hidden div display:none again
                            hiddenDiv.style.visibility = 'visible'
                            hiddenDiv.style.display = 'none'
                        })
                    })(i)
                }
            }
        },
        setup() {
            const { sendReport, data, error, error_msg, loading } = useReport()

            return {
                sendReport,
                data,
                error,
                error_msg,
                loading
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '@/assets/scss/breakpoints.scss';
    @import '@/assets/scss/containers.scss';
    @import '@/assets/scss/global.scss';
    @import '@/assets/scss/Nunito.css';

    .message__date {
        font-size: 12px;
        color: #716d6a;
        margin-bottom: 3px;
    }
    textarea {
        padding: 14px 0px;
    }
    .txta {
        width: 100%;
        max-width: 800px;
        min-height: 46px;
        overflow: hidden;
        background-color: #f4f2ee;
        border: none;
        outline: none;
        font-size: 1.4rem;
        color: #000000;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
        text-align: left;
        &:focus {
            outline: none;
            border: none;
        }
    }

    .chatResized {
        transition: 0.3s;
        @include breakpoint-max('xs-tablet') {
            transition: 0.3s;
            top: 184px !important;
        }
        .match-chat__left-box {
            // @include breakpoint-max('xs-tablet') {
            //   height: calc(100vh - 236px);
            // }
        }
        .match-chat__left-box-svg-circle {
            .mobile {
                rotate: 180deg;
            }
        }
    }
    .match-chat {
        padding-right: 91px;
        transition: 0.3s;
        @include breakpoint-max('v-large') {
            padding-right: 30px;
            padding-bottom: 20px;
        }
        @include breakpoint-max('xs-tablet') {
            padding-right: 0px;
            padding-bottom: 0px;
            position: fixed;
            top: 50px;
            z-index: 99;
            width: 100%;
            // height: calc(100vh - 50px);
            height: 100%;
        }
        &__title {
            font-size: 4.5rem;
            line-height: 5rem;
            font-weight: 400;
            color: $black;
            font-family: 'Promenade', serif;
            margin-bottom: 12px;
            @include breakpoint-max('xs-tablet') {
                display: none;
            }
        }
        &__wrapper {
            display: flex;
        }
        &__left {
            flex: 112;
            border: 1px solid $black;
            background-color: $white;
            @include breakpoint-max('v-large') {
                flex: 90;
            }
            @include breakpoint-max('large') {
                flex: 70;
            }
            @include breakpoint-max('xl-tablet') {
                flex: 60;
            }
            @include breakpoint-max('tablet') {
                flex: 50;
            }
            @include breakpoint-max('xs-tablet') {
                border-left: 0;
                border-right: 0;
            }
        }
        &__left-box {
            height: calc(100dvh - 177px - 50px - 80px);
            min-height: 450px;
            background-color: $white;
            position: relative;
            transition: 0.3s;

            // display: flex;
            // flex-direction: column;
            // align-items: stretch;
            // justify-content: flex-start;

            @include breakpoint-max('v-large') {
                min-height: 550px;
            }
            @include breakpoint-max('xs-tablet') {
                min-height: 0px;
                height: calc(100dvh - 177px - 50px - 89px);
            }
            @include breakpoint-max('xs-tablet') {
                height: calc(100dvh - 101px);
            }
        }
        &__left-box-footer {
            position: absolute;
            left: 0;
            bottom: 10px;
            display: flex;
            align-items: flex-end;
            align-items: stretch;
            width: calc(100% - 60px);
        }
        &__left-box-svg-circle {
            position: absolute;
            top: -86px;
            left: 50%;
            transform: translateX(-50%);
            width: 70px;
            height: 70px;
            background-color: $pink;
            border-radius: 50%;
            display: grid;
            place-items: center;
            svg {
                width: 36px;
                height: auto;
                transition: 0.3s;
                @include breakpoint-max('tablet') {
                    width: 15px;
                }
            }
            .desktop {
                @include breakpoint-max('xs-tablet') {
                    display: none;
                }
            }
            .mobile {
                display: none;
                @include breakpoint-max('xs-tablet') {
                    display: block;
                }
            }
            @include breakpoint-max('tablet') {
                width: 30px;
                height: 30px;
                top: -66px;
            }
            @include breakpoint-max('xs-tablet') {
                top: -45px;
            }
        }
        &__left-box-messages {
            margin-bottom: 10px;
            margin-top: 50px;
            overflow-x: hidden;
            overflow-y: scroll;
            margin-right: 2px;
            height: calc(100% - 76px); //here
            scroll-behavior: smooth;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: $pink;
                border-radius: 100px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba($color: $pink, $alpha: 0.5);
            }

            .message {
                &__cards {
                    display: flex;
                    padding-bottom: 10px;
                    font-weight: 700;
                    align-items: center;
                    svg {
                        margin-left: 7px;
                    }
                }
                .message__content {
                    width: fit-content;
                    background-color: #f4f2ee;
                    padding: 10px 15px;
                    margin-bottom: 9px;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    color: $black;
                    font-weight: 400;
                    font-family: 'Open Sans', sans-serif;
                    text-align: left;
                    margin-right: auto;
                    border-radius: 10px 10px 10px 0;
                    background-color: #e1dbd5;
                }
            }

            .my-message {
                .message__content {
                    text-align: right;
                    margin-left: auto;
                    margin-right: 0;
                    border-radius: 10px 10px 0 10px;
                    background-color: #f4f2ee;
                }

                .message__wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
            .cards-message {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                padding-bottom: 8px;

                &__message {
                    position: relative;
                }

                &__date {
                    // border: 1px dashed;
                }
                .ask {
                    border-bottom: 1px solid $black;
                }
                .cards-message-ask {
                    display: flex;
                    align-items: center;
                    column-gap: 7px;
                }
            }
        }
        &__left-box-messages-content {
            padding: 0 50px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            @include breakpoint-max('tablet') {
                padding: 0 10px;
            }
        }
        &__left-box-actions {
            padding: 0 30px;
            padding-bottom: 14px;
            column-gap: 12px;
            height: 67px;
            display: flex;
            align-items: center;
            // fast fix
            @include breakpoint-max('mobile') {
                position: fixed;
                left: 0;
                bottom: 0;
                width: 100vw;
                z-index: 200;
            }
        }
        &__left-box-actions-input {
            background-color: #f4f2ee;
            border-radius: 500px;
            flex: 1;
            display: flex;
            align-items: center;
            height: 53px;
            padding: 0 15px;
            padding-left: 25px;
            position: relative;
            &-svg {
                appearance: none;
                border: none;
                background-color: transparent;
                position: absolute;
                right: 20px;
                top: 10px;
                width: 35px;
                transform: scale(0.8);
            }
            input {
                display: block;
                height: 22px;
                flex: 1;
                column-gap: 15px;
                border: none;
                outline: none;
                background-color: transparent;
                font-size: 1.6rem;
                line-height: 3rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
            }
        }
        &__left-box-actions-input-emoji {
            svg {
                width: 28px;
                min-width: 28px;
                cursor: pointer;
            }
        }
        &__left-box-actions-send {
            svg {
                min-width: 29px;
                width: 29px;
                cursor: pointer;
            }
        }
        &__left-box-cards {
            position: absolute;
            bottom: 0px;
            left: -416px;
            width: 100%;
            max-width: 376px;
            border: 1px solid $black;
            @include breakpoint-max('v-large') {
                left: -248px;
                max-width: 230px;
            }
        }
        &__left-box-cards-title {
            width: 100%;
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 16px;
            background-color: #e1dbd5;
            border-bottom: 1px solid $black;
            p {
                font-size: 1.2rem;
                line-height: 3rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
            }
        }
        &__left-box-cards-title-circle {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            display: grid;
            place-items: center;
            background-color: $pink;
        }
        &__left-box-cards-content {
            padding: 22px 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 20px;
            p {
                font-size: 1.6rem;
                line-height: 2rem;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
            }
            @include breakpoint-max('v-large') {
                flex-direction: column;
                row-gap: 20px;
            }
        }
        &__left-box-cards-content-buttons {
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            button {
                width: 95px;
                text-align: center;
                padding: 7px;
                border: 1px solid $pink;
                background-color: $pink;
                color: $white;
                font-size: 1.2rem;
                line-height: 1.6rem;
                font-weight: 700;
                font-family: 'Open Sans', sans-serif;
                cursor: pointer;
                &:last-child {
                    background-color: #e1dbd5;
                    color: $black;
                    border-color: $black;
                }
                @include breakpoint-max('v-large') {
                    width: 140px;
                    padding: 14px 0;
                }
            }
        }
        &__right {
            flex: 24;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include breakpoint-max('xs-tablet') {
                display: none;
            }
        }
        &__right-top {
            picture {
                display: block;
                padding-left: 40px;
                margin-bottom: 13px;
                img {
                    display: block;
                    width: 100%;
                    border: 1px solid $black;
                }
                @include breakpoint-max('v-large') {
                    padding-left: 15px;
                }
            }
        }
        &__right-top-name {
            padding: 7px 12px 7px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 15px;
            background-color: $pink;
            p {
                color: $white;
                font-size: 2.3rem;
                line-height: 4.2rem;
                font-family: 'Open Sans', sans-serif;
                font-weight: 700;
            }
            svg {
                width: 22px;
                min-width: 22px;
            }
        }
        &__right-bottom {
            padding-left: 40px;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            button {
                width: 100%;
                max-width: 200px;
                padding: 15px 10px;
                border: 1px solid $black;
                background-color: #e1dbd5;
                color: $black;
                font-size: 1.6rem;
                line-height: 2rem;
                font-weight: 700;
                cursor: pointer;
                &:hover {
                    background-color: #d13724;
                    color: #fff;
                    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
            }
            @include breakpoint-max('v-large') {
                padding-left: 15px;
            }
        }
    }

    .pwa {
        max-width: 500px;
        width: 100%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #f4f2ee;
        padding: 20px;
        border-radius: 10px;
        z-index: 99999;
        display: none;
        @include breakpoint-max('xs-tablet') {
            display: block;
        }
        &__itle {
            padding-right: 20px;
        }
        &__description {
            margin: 20px 0;
        }
        &__icon {
            width: 20px;
            height: 20px;
            fill: #000;
            top: 5px;
            position: relative;
        }
        &__bottom {
            border-top: 1px solid lighten(#000, 80%);
            padding-top: 10px;
            margin-top: 10px;
        }
        &__close {
            right: 10px;
            top: 10px;
            border: none;
            background: none;
            appearance: none;
            cursor: pointer;
            width: 20px;
        }
        &__top {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
</style>
